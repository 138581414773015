import { Button, Modal, Select } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import useSWR from 'swr';
import { searchInString } from '../../../../../lib/helpers';

const PAGE_URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/pages/inter-intra`;
const URL = `${process.env.REACT_APP_BASE_URL_API_URL}/next/private/arinfo/trainings`;

const SWROptions = {
  refreshInterval: 0,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const formatOptions = (array, disabledItems) => {
  const options = [];

  if (!array || !Array.isArray(array)) {
    return options;
  }

  // Ajout des formations métiers
  // options.push({
  //   label: 'Formations métiers',
  //   options: array
  //     .filter((item) => item.type === 'metier')
  //     .map((item) => {
  //       const disabled = !!disabledItems.find((disabledItem) => disabledItem.formation._id === item._id);

  //       return {
  //         label: item.title,
  //         value: item._id,
  //         disabled,
  //       };
  //     })
  //     .sort((a, b) => a.label.localeCompare(b.label)),
  // });

  // Ajout des formations outils
  options.push({
    label: 'Formations outils',
    options: array
      .filter((item) => item.type === 'outil')
      .map((item) => {
        const disabled = !!disabledItems.find((disabledItem) => disabledItem.formation === item._id);

        return {
          label: disabled ? `${item.title} (déjà existante)` : item.title,
          value: item._id,
          disabled,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label)),
  });

  return options;
};

const SelectFormationModal = ({ category, categoryFormations }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const { data, isValidating } = useSWR(open ? URL : null, SWROptions);
  const { data: formations, isValidating: isFormationsValidating } = useSWR(
    open ? `${PAGE_URL}/formations` : null,
    SWROptions,
  );
  const options = useMemo(() => formatOptions(data?.data || [], formations?.data || []), [data, formations?.data]);
  const history = useHistory();

  useEffect(() => {
    if (open) {
      setValue(null);
    }
  }, [open]);

  return (
    <>
      <Modal
        visible={open}
        destroyOnClose
        title={category?.name ? `Ajouter une formation dans ${category.name}` : 'Ajouter une formation'}
        okText="Continuer"
        okButtonProps={{ disabled: value === null }}
        onCancel={() => setOpen(false)}
        onOk={() => history.push(`/arinfo/inter-intra/${category._id}/formations/${value}`)}
      >
        <Select
          {...{ options, value }}
          showSearch
          loading={isValidating || isFormationsValidating}
          placeholder="Sélectionner dans la liste"
          onChange={(newValue) => setValue(newValue)}
          filterOption={(input, option) => searchInString(input, option.label)}
          style={{ width: '100%' }}
        />
      </Modal>
      <Button size="small" icon={<PlusOutlined />} onClick={(event) => setOpen(true)}>
        Ajouter une formation
      </Button>
    </>
  );
};

export default SelectFormationModal;

import { Button, Modal, Typography, Space, Card, Collapse, Tooltip, Popconfirm, Empty } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';

import useFetch from '../../../../hooks/useFetch';
import { aiRoutes } from '../../../../lib/routes';
import Dragger from 'antd/lib/upload/Dragger';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const FILECONTENT_STATUS = {
  SUCCESS: {
    icon: <CheckCircleOutlined style={{ color: 'green' }} />,
    color: 'success',
  },
  WARNING: {
    icon: <ExclamationCircleOutlined style={{ color: 'orange' }} />,
    color: 'warning',
    popup:
      "Le contenu de ce fichier comporte des types de variables non compatibles avec l'importation. Le fichier sera partiellemnt importé.",
  },
  ERROR: {
    icon: <CloseCircleOutlined style={{ color: 'red' }} />,
    color: 'error',
    popup: 'Le contenu de ce fichier est erroné. Il ne peut pas être importé.',
  },
};

const cleanModuleObject = (item) => {
  // Ajoute la description si manquante
  if (!Object.keys(item).includes('description')) {
    item.description = 'Les descriptions sont maintenant obligatoires pour les menus ! Mettez ce module à jour';
  }

  // Nettoie les variables
  const cleanedVariables = [];

  for (let i = 0; i < item?.variables.length; i++) {
    if (item.variables[i].type !== 'PERSONAS') {
      cleanedVariables.push(item.variables[i]);
    }
  }

  item.variables = cleanedVariables;

  return item;
};

const moduleContentValidation = (item) => {
  // Retourne une erreur si le fichier ne comporte pas tous les titres
  if (!Object.keys(item).includes('title') || !Object.keys(item).includes('content')) {
    return 'ERROR';
  }

  // Vérifie les types de variables
  const variableTypes = (item?.variables || []).map((variable) => variable.type);

  // Retourne un avertissement si des variables seront rejetées
  if (variableTypes.includes('PERSONAS') || !Object.keys(item).includes('description')) {
    return 'WARNING';
  }

  return 'SUCCESS';
};

const ImportModuleElement = ({ item, key, onDelete, isProcessing, ...props }) => {
  const displayedItem = { ...item, filename: undefined, id: undefined };
  const itemStatus = moduleContentValidation(displayedItem);

  return (
    <Collapse.Panel
      key={key}
      header={item.filename}
      extra={
        <Space>
          {FILECONTENT_STATUS[itemStatus]?.popup ? (
            <Tooltip title={FILECONTENT_STATUS[itemStatus].popup}>{FILECONTENT_STATUS[itemStatus].icon}</Tooltip>
          ) : (
            <>{FILECONTENT_STATUS[itemStatus].icon}</>
          )}
          <div onClick={(event) => event.stopPropagation()}>
            <Popconfirm {...popconfirmProps} onConfirm={onDelete} disabled={isProcessing}>
              <Button icon={<DeleteOutlined />} />
            </Popconfirm>
          </div>
        </Space>
      }
      {...props}
    >
      <pre style={{ padding: '5px', whiteSpace: 'pre-wrap' }}>{JSON.stringify(displayedItem, null, 2)}</pre>
    </Collapse.Panel>
  );
};

const ImportModuleAiModal = ({ workspace, isOpen, onClose, onFinish }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { handleSubmit, control, reset } = useForm({
    defaultValues: { application: workspace === 'general' ? null : workspace, files: [] },
  });
  const { fields, append, remove } = useFieldArray({ control, name: 'files' });
  const { post } = useFetch();

  const onSubmit = async (form) => {
    setIsProcessing(true);

    for (let i = form.files.length - 1; i >= 0; i--) {
      let moduleFile = { ...form.files[i], application: form.application };

      // Rejette les erreurs
      if (moduleContentValidation(moduleFile) !== 'ERROR') {
        // Corrige les warnings
        if (moduleContentValidation(moduleFile) === 'WARNING') {
          moduleFile = cleanModuleObject(moduleFile);
        }

        // Importe le fichier
        const results = await post(aiRoutes.modules, JSON.stringify(moduleFile));

        if (results.status === 201) {
          remove(i);
        }
      } else {
        remove(i);
      }
    }

    onFinish();
    setIsProcessing(false);
  };

  const appendFile = (file) => {
    // Lecture du contenu du fichier
    const fileReader = new FileReader();
    fileReader.readAsText(file);

    // Ajout du fichier à la liste
    fileReader.onload = (e) => {
      // Convertir en JSON
      const fileObject = JSON.parse(e.target.result);
      fileObject.filename = file.name;

      append(fileObject);
    };
  };

  return (
    <>
      <Modal
        title={`Importation de modules`}
        visible={isOpen}
        onCancel={() => {
          reset();
          onClose();
        }}
        width={750}
        footer={[
          <Button
            key="back"
            onClick={() => {
              reset();
              onClose();
            }}
            disabled={isProcessing}
          >
            Annuler
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={isProcessing || !fields?.length}
            onClick={handleSubmit(onSubmit)}
          >
            Importer
          </Button>,
        ]}
      >
        <Card size="small" style={{ minHeight: 300, maxHeight: 300, overflowY: 'scroll', marginBottom: '20px' }}>
          {!fields?.length ? (
            <Empty />
          ) : (
            <Collapse bordered={false}>
              {fields.map((item, index) => {
                return (
                  <ImportModuleElement
                    item={item}
                    key={index}
                    isProcessing={isProcessing}
                    onDelete={() => remove(index)}
                  />
                );
              })}
            </Collapse>
          )}
        </Card>
        <Dragger
          accept="application/json"
          multiple
          showUploadList={false}
          height={100}
          disabled={isProcessing}
          beforeUpload={(file) => {
            appendFile(file);

            return false;
          }}
        >
          <Typography.Text>Sélectionnez ou déposez un fichier JSON</Typography.Text>
        </Dragger>
      </Modal>
    </>
  );
};

export default ImportModuleAiModal;

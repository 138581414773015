import { useState } from 'react';
import { Form, DatePicker, Select, Button, Row, Col, Alert, Radio, Space } from 'antd';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import useSWR from 'swr';
import { formationRoutes, userRoutes, groupRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';
import StudentImportFormationAgency from './StudentImportFormationAgency';
import { formationValidator } from './validation';
import moment from 'moment';

const { Item } = Form;
const { Group } = Radio;

const swrOptions = {
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
};

const StudentImportFormation = ({ onCancel, onNext }) => {
  const [errors, setErrors] = useState({});
  const { control, setValue } = useFormContext();
  const [formation, groupMode, group, agencyMode, students] = useWatch({
    control,
    name: ['formation', 'groupMode', 'group', 'agencyMode', 'students'],
  });
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, swrOptions);
  const { data: users, isValidating } = useSWR(userRoutes.default, swrOptions);
  const { data: groups, isValidating: isGroupsValidating } = useSWR(groupRoutes.default, swrOptions);

  const handleCancel = () => {
    setValue('withFormation', false);
    setValue('withGroup', false);
    onCancel();
  };

  const handleFilter = (inputValue, option) => {
    return option.label.toLowerCase().includes(inputValue.toLowerCase());
  };

  const handleNext = () => {
    const newErrors = formationValidator({ ...formation, groupMode, agencyMode }, students);

    if (groupMode === 'select' && !group) {
      newErrors.group = 'Ce champ est requis';
    }

    if (Object.keys(newErrors).length !== 0) {
      return setErrors(newErrors);
    } else {
      setErrors({});
      return onNext();
    }
  };

  const onGroupChange = (value, callback) => {
    const group = groups.data.find((item) => item._id === value);

    setValue('formation.formation', group?.formation?._id || null);
    setValue('formation.referent', group?.referent?._id || null);
    setValue('formation.type', group.type);
    setValue('formation.startAt', moment(new Date(group.startAt)));
    setValue('formation.endAt', moment(new Date(group.endAt)));
    setValue('formation.realizationMethod', group?.realizationMethod || null);
    setValue('formation.formationDomain', group?.formationDomain || null);

    if (group?.remote) {
      setValue('formation.agency', 'DISTANCIEL');
      setValue('formation.studentsAgency', null);
    } else {
      setValue('formation.agency', group?.agency?._id || null);
      setValue('formation.studentsAgency', group?.agency?._id || null);
    }

    callback(value);
  };

  const onGroupModeChange = (value, callback) => {
    if (groupMode === 'select') {
      setValue('group', null);
      setValue('formation.formation', null);
      setValue('formation.referent', null);
      setValue('formation.type', null);
      setValue('formation.startAt', null);
      setValue('formation.endAt', null);
      setValue('formation.agency', null);
      setValue('formation.studentsAgency', null);
      setValue('formation.realizationMethod', null);
      setValue('formation.formationDomain', null);
    }

    callback(value);
  };

  return (
    <>
      <Controller
        control={control}
        name="groupMode"
        render={({ field }) => (
          <Group
            {...field}
            onChange={(e) => onGroupModeChange(e.target.value, field.onChange)}
            style={{ marginBottom: groupMode !== 'new' ? 16 : 0 }}
          >
            <Radio value="select">Utiliser une session existante</Radio>
            <Radio value="new">Créer une nouvelle session</Radio>
            <Radio value="none">Pas de session</Radio>
          </Group>
        )}
      />
      {groupMode === 'select' && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item label="Session" required validateStatus={errors?.group && 'error'} help={errors?.group}>
              <Controller
                control={control}
                name="group"
                render={({ field }) => (
                  <Select
                    {...field}
                    onChange={(value) => onGroupChange(value, field.onChange)}
                    placeholder="Sélectionnez dans la liste"
                    disabled={!groups || isGroupsValidating}
                    loading={isGroupsValidating}
                    options={
                      groups?.data
                        .sort((a, b) => sort(a, b, 'name'))
                        .reverse()
                        .map((item) => ({
                          label: item.name,
                          value: item._id,
                        })) || []
                    }
                  />
                )}
              />
            </Item>
          </Col>
        </Row>
      )}
      {groupMode === 'new' && (
        <Alert
          message="Une nouvelle session sera automatique créée avec les informations ci-dessous"
          type="info"
          showIcon
          style={{ marginTop: 16, marginBottom: 16 }}
        />
      )}
      {['new', 'none'].includes(groupMode) && (
        <>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Item label="Formation" required validateStatus={errors?.formation && 'error'} help={errors?.formation}>
                <Controller
                  control={control}
                  name="formation.formation"
                  render={({ field }) => (
                    <Select
                      {...field}
                      filterOption={handleFilter}
                      showSearch
                      placeholder="Sélectionnez dans la liste"
                      disabled={!formations || isFormationsValidating || groupMode === 'select'}
                      loading={isFormationsValidating}
                      options={
                        formations?.data
                          .sort((a, b) => sort(a, b, 'title'))
                          .map((program) => ({
                            label: program.title,
                            value: program._id,
                          })) || []
                      }
                    />
                  )}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Item
                label="Formateur référent"
                required
                validateStatus={errors?.referent && 'error'}
                help={errors?.referent}
              >
                <Controller
                  control={control}
                  name="formation.referent"
                  render={({ field }) => (
                    <Select
                      {...field}
                      filterOption={handleFilter}
                      showSearch
                      placeholder="Sélectionnez dans la liste"
                      disabled={!users || isValidating || groupMode === 'select'}
                      loading={isValidating}
                      options={
                        users?.data
                          .map((user) => ({
                            label: `${user.last_name.toUpperCase()} ${
                              user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                            }`,
                            value: user._id,
                          }))
                          .sort((a, b) => sort(a, b, 'label')) || []
                      }
                    />
                  )}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Type de session" required validateStatus={errors?.type && 'error'} help={errors?.type}>
                <Controller
                  control={control}
                  name="formation.type"
                  render={({ field }) => (
                    <Radio.Group {...field} disabled={groupMode === 'select'}>
                      <Radio value="TEMPS CONTINU">Temps continu</Radio>
                      <Radio value="ALTERNANCE">Alternance</Radio>
                    </Radio.Group>
                  )}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Item label="Date d'entrée" required validateStatus={errors?.startAt && 'error'} help={errors?.startAt}>
                <Controller
                  control={control}
                  name="formation.startAt"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      allowClear
                      format="DD/MM/YYYY"
                      placeholder="Sélectionnez une date"
                      disabled={groupMode === 'select'}
                    />
                  )}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Date de fin"
                required
                validateStatus={errors?.endAt && 'error'}
                help={errors?.endAt}
                extra={`L'accès à Formao sera bloqué 3 mois après cette date`}
              >
                <Controller
                  control={control}
                  name="formation.endAt"
                  render={({ field }) => (
                    <DatePicker
                      {...field}
                      allowClear
                      format="DD/MM/YYYY"
                      placeholder="Sélectionnez une date"
                      disabled={groupMode === 'select'}
                    />
                  )}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={[16, 16]}>
            <Col span={12}>
              <Item
                label="Modalité de réalisation"
                validateStatus={errors?.realizationMethod && 'error'}
                help={errors?.realizationMethod}
                required
              >
                <Controller
                  name="formation.realizationMethod"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group {...field}>
                      <Space direction="vertical">
                        <Radio className={errors?.realizationMethod && 'radio-danger'} value="PRESENTIEL">
                          En présentiel
                        </Radio>
                        <Radio className={errors?.realizationMethod && 'radio-danger'} value="DISTANCIEL">
                          En télé-présentiel
                        </Radio>
                        <Radio className={errors?.realizationMethod && 'radio-danger'} value="MIXTE">
                          Mixte
                        </Radio>
                      </Space>
                    </Radio.Group>
                  )}
                />
              </Item>
            </Col>
            <Col span={12}>
              <Item
                label="Domaine de formation"
                validateStatus={errors?.formationDomain && 'error'}
                help={errors?.formationDomain}
                required
              >
                <Controller
                  name="formation.formationDomain"
                  control={control}
                  render={({ field }) => (
                    <Radio.Group {...field}>
                      <Space direction="vertical">
                        <Radio className={errors?.formationDomain && 'radio-danger'} value="BATIMENT">
                          Bâtiment, modélisation (NSF 230)
                        </Radio>
                        <Radio className={errors?.formationDomain && 'radio-danger'} value="IMPRESSION">
                          Impression, édition, graphisme (NSF 322)
                        </Radio>
                        <Radio className={errors?.formationDomain && 'radio-danger'} value="INFORMATIQUE">
                          Informatique, développement (NSF 326)
                        </Radio>
                        <Radio className={errors?.formationDomain && 'radio-danger'} value="COMMUNICATION">
                          Communication digitale et web (NSF 320)
                        </Radio>
                        <Radio className={errors?.formationDomain && 'radio-danger'} value="IMAGE">
                          Techniques image, son et vidéo (NSF 323)
                        </Radio>
                      </Space>
                    </Radio.Group>
                  )}
                />
              </Item>
            </Col>
          </Row>
        </>
      )}
      {['select', 'new', 'none'].includes(groupMode) && <StudentImportFormationAgency {...{ errors }} />}
      {Object.keys(errors).length !== 0 && (
        <Alert
          message="Erreur de validation"
          description="Des erreurs on été détectées, veuillez les corriger avant de continuer."
          type="error"
          showIcon
          style={{ marginTop: 16 }}
        />
      )}
      <div style={{ marginTop: 24, display: 'flex', justifyContent: 'space-between' }}>
        <Button htmlType="button" onClick={handleCancel}>
          Annuler l'assignation d'une formation
        </Button>
        <Button htmlType="button" onClick={handleNext}>
          Terminer l'importation
        </Button>
      </div>
    </>
  );
};

export default StudentImportFormation;

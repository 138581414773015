import { addMonths, eachMonthOfInterval, getDate, getDaysInMonth, setDate, nextMonday } from 'date-fns';
import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { Avatar, Card, Col, Form, Row, Select, Space } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import useSWR from 'swr';
import DebounceAutoComplete from './DebounceAutoComplete';
import useFetch from '../../../../hooks/useFetch';
import { formationRoutes, groupRoutes, studentRoutes, userRoutes } from '../../../../lib/routes';
import { sort } from '../../../../shared/utils';

const FormaoStudentForm = ({ disabled, setFormsOptions, toggleShowAllForms, formattedForms }) => {
  const [userOptions, setUserOptions] = useState([]);
  const [studentOptions, setStudentOptions] = useState([]);
  const [groupOptions, setGroupOptions] = useState([]);
  const { control, watch, setValue } = useFormContext();
  const { errors } = useFormState({ control });
  const { get } = useFetch();
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: groups, isValidating: isGroupsValidating } = useSWR(groupRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: students, isValidating: isStudentsValidating } = useSWR(studentRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const studentFormation = watch('studentFormation');
  const studentGroup = watch('studentGroup');
  const studentFirstName = watch('studentFirstName');
  const studentLastName = watch('studentLastName');
  const studentEmail = watch('studentEmail');
  const reportsType = watch('reportsType');

  const fetchUserList = async (value) => {
    const results = await get(userRoutes.default + '?search=' + value);

    if (results.status !== 200) {
      return [];
    } else {
      const formattedData = results.data.map((result) => ({
        label: result.last_name.toUpperCase() + ' ' + capitalize(result.first_name),
        value: result._id,
      }));
      const sortedData = formattedData.sort((a, b) => sort(a, b, 'label'));

      return sortedData;
    }
  };

  const filterGroups = (value) => {
    const formattedGroups = (groups?.data || []).flatMap((group) => {
      if (group?.formation?._id !== value) {
        return [];
      }

      return {
        label: group.name,
        value: group._id,
      };
    });

    setGroupOptions((formattedGroups || []).sort((a, b) => sort(a, b, 'label')));
  };

  const filterStudents = (value, field) => {
    const formattedStudents = (students?.data || []).flatMap((student) => {
      if (!student.formations.find((formation) => formation[field] === value)) {
        return [];
      }

      return {
        label: student.last_name.toUpperCase() + ' ' + capitalize(student.first_name),
        value: student._id,
      };
    });

    setStudentOptions((formattedStudents || []).sort((a, b) => sort(a, b, 'label')));
  };

  useEffect(() => {
    if (studentGroup) {
      filterStudents(studentGroup, 'group');
    }

    if (studentFormation) {
      filterGroups(studentFormation);
      filterStudents(studentFormation, 'formation');
    }
  }, [students, students?.data, groups, groups?.data]);

  const handleFormation = (value, onChange) => {
    if (value !== studentFormation) {
      if (value) {
        const newOptions = formattedForms?.filter((item) =>
          item.formations.find((formation) => formation.toString() === value.toString()),
        );

        toggleShowAllForms(newOptions.filter((option) => option.formType === 'FORMATION').length === 0);
        setFormsOptions(newOptions);
      }

      setValue('studentFormationId', null);
      setValue('studentGroup', null);
      setValue('studentEmail', null);
      setValue('studentFirstName', null);
      setValue('studentLastName', null);
      setValue('studentId', null);

      filterStudents(value, 'formation');
      filterGroups(value);

      onChange(value || null);
    }
  };

  const handleGroup = (value, onChange) => {
    if (value !== studentGroup) {
      setValue('studentEmail', null);
      setValue('studentFirstName', null);
      setValue('studentLastName', null);
      setValue('studentId', null);
      onChange(value || null);

      if (!value) {
        if (studentFormation) {
          filterStudents(studentFormation, 'formation');
        }
      } else {
        filterStudents(value, 'group');
      }
    }
  };

  const handleStudent = (value, onChange) => {
    const match = value ? students?.data?.find((student) => student._id === value) : null;

    if (match) {
      const formation = match.formations.find((formation) => formation.formation === studentFormation);
      const filteredForms = formattedForms.filter(
        (form) => form.formations.includes(formation.formation.toString()) && form.formType === reportsType,
      );

      if (reportsType === 'COMPANY') {
        const firstReportData = {
          sendAt: addMonths(new Date(formation.startAt), 2),
          form: filteredForms.filter((form) => form.reportType === 'INTEGRATION'),
        };
        const secondReportData = {
          sendAt: addMonths(new Date(formation.startAt), 6),
          form: filteredForms.filter((form) => form.reportType === 'MI_PARCOURS'),
        };
        const thirdReportData = {
          sendAt: addMonths(new Date(formation.startAt), 10),
          form: filteredForms.filter((form) => form.reportType === 'FINAL'),
        };

        setValue(`reports.[0]`, {
          sendAt: firstReportData?.sendAt,
          form: firstReportData?.form?.length === 1 ? firstReportData.form[0] : null,
        });

        setValue(`reports.[1]`, {
          sendAt: secondReportData?.sendAt,
          form: secondReportData?.form?.length === 1 ? secondReportData.form[0] : null,
        });

        setValue(`reports.[2]`, {
          sendAt: thirdReportData?.sendAt,
          form: thirdReportData?.form?.length === 1 ? thirdReportData.form[0] : null,
        });
      }

      if (reportsType === 'FORMATION') {
        const firstReportData = {
          sendAt: addMonths(new Date(formation.startAt), 3),
          form: filteredForms.filter((form) => form.reportType === 'MI_PARCOURS'),
        };
        const secondReportData = {
          sendAt: addMonths(new Date(formation.startAt), 6),
          form: filteredForms.filter((form) => form.reportType === 'FINAL'),
        };

        setValue(`reports.[0]`, {
          sendAt: firstReportData?.sendAt,
          form: firstReportData?.form?.length === 1 ? firstReportData.form[0] : null,
        });

        setValue(`reports.[1]`, {
          sendAt: secondReportData?.sendAt,
          form: secondReportData?.form?.length === 1 ? secondReportData.form[0] : null,
        });
      }

      if (reportsType === 'MENSUEL') {
        // Récupère tous les mois entre le début et la fin de formation
        let eachMonths = eachMonthOfInterval({ start: new Date(formation.startAt), end: new Date(formation.endAt) });
        // Récupère la date de début de formation
        const startDate = getDate(new Date(formation.startAt));
        // Récupère le nombre de jours dans le mois de la date de début de formation
        const startDateDaysInMonth = getDaysInMonth(new Date(formation.startAt));
        const formattedMonths = eachMonths?.map((month) => {
          // Récupère le nombre de jours dans le mois
          const daysInMonth = getDaysInMonth(new Date(month));
          // Définie la date en fonction de la date du début de formation
          const formattedDate = setDate(new Date(month), daysInMonth < startDate ? daysInMonth : startDate);
          // Définie la date qui permettra l'affichage dans la liste des suivis (sans la prise en compte des w-e)
          const originalDate = startDate <= startDateDaysInMonth / 2 ? formattedDate : addMonths(formattedDate, 1);
          // Définie la date d'envoi du suivi
          const sendAt = addMonths(formattedDate, 1);
          // Définie la date du suivi qui prend en compte les w-e
          const formattedSendAt = [6, 0].includes(sendAt.getDay()) ? nextMonday(sendAt) : sendAt;
          let reportForm = null;

          // Défini le formulaire sélectionné par défaut en fonction de la formation
          if (formattedForms && formation?.formation) {
            const filteredForms = formattedForms.filter(
              (form) => form.formations.includes(formation?.formation?.toString()) && form.formType === 'MENSUEL',
            );

            if (filteredForms?.length === 1) {
              reportForm = filteredForms[0];
            }
          }

          return { originalDate, sendAt: formattedSendAt, form: reportForm };
        });

        setValue('reports', formattedMonths);
      }

      setValue('studentEmail', match.email);
      setValue('studentFirstName', match.first_name);
      setValue('studentLastName', match.last_name);
      setValue('studentFormationId', formation._id);
    } else {
      setValue('studentEmail', null);
      setValue('studentFirstName', null);
      setValue('studentLastName', null);
      setValue('studentFormationId', null);
    }

    onChange(value || null);
  };

  return (
    <>
      <Col span={12}>
        <Form.Item
          label="Formation"
          validateStatus={errors?.studentFormation?.message && 'error'}
          help={errors?.studentFormation?.message}
          required
        >
          <Controller
            name="studentFormation"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                filterSort={(a, b) => sort(a, b, 'label')}
                onChange={(value) => handleFormation(value, field.onChange)}
                disabled={disabled || !formations || isFormationsValidating}
                placeholder="Sélectionnez dans la liste"
              >
                {formations?.data
                  ?.filter((item) => !item.disabled)
                  ?.map((formation) => (
                    <Select.Option value={formation._id} label={formation.title} key={formation._id}>
                      {formation.title}
                    </Select.Option>
                  ))}
              </Select>
            )}
          />
        </Form.Item>
        {studentFormation && (
          <>
            <Form.Item
              label="Groupe"
              validateStatus={errors?.studentGroup?.message && 'error'}
              help={errors?.studentGroup?.message}
            >
              <Controller
                control={control}
                name="studentGroup"
                render={({ field }) => (
                  <Select
                    value={field.value}
                    options={groupOptions}
                    allowClear
                    disabled={disabled || !groups || isGroupsValidating}
                    onChange={(value) => handleGroup(value || null, field.onChange)}
                  />
                )}
              />
            </Form.Item>
            <>
              <Form.Item
                label="Apprenant"
                validateStatus={errors?.studentId?.message && 'error'}
                help={errors?.studentId?.message}
                required
              >
                <Controller
                  control={control}
                  name="studentId"
                  render={({ field }) => (
                    <Select
                      value={field.value}
                      options={studentOptions}
                      disabled={disabled || !students || isStudentsValidating}
                      onChange={(value) => handleStudent(value || null, field.onChange)}
                    />
                  )}
                />
              </Form.Item>
            </>
          </>
        )}
        {studentFirstName && studentEmail && (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Card>
              <Space>
                <Avatar icon={<UserOutlined />} size="large" />
                <Col>
                  <Row>
                    {studentLastName?.toUpperCase() +
                      ' ' +
                      studentFirstName?.charAt(0).toUpperCase() +
                      studentFirstName?.slice(1)}
                  </Row>
                  <Row>{studentEmail}</Row>
                </Col>
              </Space>
            </Card>

            <Form.Item
              label="Responsable du suivi"
              validateStatus={errors?.referent?.message && 'error'}
              help={errors?.referent?.message}
              required
            >
              <DebounceAutoComplete
                fieldName="referent"
                fetchOptions={fetchUserList}
                options={userOptions}
                setOptions={setUserOptions}
                disabled={disabled}
              />
            </Form.Item>
          </Space>
        )}
      </Col>
    </>
  );
};
export default FormaoStudentForm;

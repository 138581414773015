import { Tabs, List } from 'antd';
import { CodeOutlined, MessageOutlined } from '@ant-design/icons';

import ModuleAiLibraryItem from './ModulesAiLibraryItem';
import { useDrop } from 'react-dnd';
import { useRef } from 'react';

const { TabPane } = Tabs;

const ModuleAiLibraryTable = ({ modules, onMove, onPreview, disabled }) => {
  const ref = useRef(null);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: 'CARD',
    canDrop: () => !disabled,
    collect: (monitor) => ({
      canDrop: monitor.canDrop(),
      isOver: monitor.isOver(),
    }),
    drop: (item, monitor) => {
      item.remove();
    },
  });

  drop(ref);

  return (
    <div ref={ref}>
      <Tabs>
        <TabPane
          key="enabled"
          tab={
            <>
              <CodeOutlined /> {`Modules généraux (${modules.general.length})`}
            </>
          }
        >
          <div
            style={{
              maxHeight: 400,
              overflow: 'auto',
              border: '1px solid rgba(140, 140, 140, 0.35)',
            }}
          >
            <List
              size="small"
              dataSource={modules.general}
              itemLayout="horizontal"
              renderItem={(item) => {
                return (
                  <ModuleAiLibraryItem
                    key={`module.${item._id}`}
                    moduleItem={item}
                    onMove={onMove}
                    onDelete={() => {}}
                    onPreview={() => onPreview(item)}
                    disabled={disabled}
                  />
                );
              }}
            />
          </div>
        </TabPane>
        {modules?.application ? (
          <TabPane
            key="disabled"
            tab={
              <>
                <MessageOutlined /> {`Modules de l'application (${modules.application.length})`}
              </>
            }
          >
            <div
              style={{
                maxHeight: 400,
                overflow: 'auto',
                border: '1px solid rgba(140, 140, 140, 0.35)',
              }}
            >
              <List
                size="small"
                dataSource={modules.application}
                itemLayout="horizontal"
                renderItem={(item) => {
                  return (
                    <ModuleAiLibraryItem
                      key={`module.${item._id}`}
                      moduleItem={item}
                      onMove={onMove}
                      onDelete={() => {}}
                      onPreview={() => onPreview(item)}
                      disabled={disabled}
                    />
                  );
                }}
              />
            </div>
          </TabPane>
        ) : null}
      </Tabs>
    </div>
  );
};

export default ModuleAiLibraryTable;

import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { Card, Space, Typography, Image } from 'antd';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

const CONVERSATION_AI_LABELS = {
  chatgpt: 'ChatGPT',
  mistral: 'Mistral AI',
};

const ConversationViewer = ({ conversation, displayPrompt }) => {
  return (
    <Card style={{ minHeight: 100, maxHeight: 400, overflowY: 'scroll' }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {displayPrompt && conversation?.prompt ? (
          <span>
            <div
              style={{
                padding: 10,
                backgroundColor: '#EEEEF4',
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <Typography.Text type="secondary" italic>
                {conversation.prompt}
              </Typography.Text>
            </div>
          </span>
        ) : null}
        {(conversation.messages || [])?.map((msg) => (
          <span>
            <div
              style={{
                padding: 10,
                ...(msg.role === 'user'
                  ? { backgroundColor: '#adc6ff', marginLeft: 20 }
                  : { backgroundColor: '#d6e4ff', marginRight: 20 }),
              }}
            >
              <Space direction="vertical" style={{ display: 'flex' }}>
                {msg.role === 'user' ? (
                  <Typography.Text strong>{`${capitalize(
                    conversation?.user.first_name,
                  )} ${conversation?.user.last_name.toUpperCase()}`}</Typography.Text>
                ) : (
                  <Space size="small">
                    <Typography.Text strong>{CONVERSATION_AI_LABELS[conversation?.ai?.system]}</Typography.Text>{' '}
                    {conversation?.ai?.release && (
                      <Typography.Text type="secondary" italic>
                        ({conversation.ai.release})
                      </Typography.Text>
                    )}
                  </Space>
                )}
                {!!msg?.content && <Typography.Text>{msg.content}</Typography.Text>}
                {!!msg?.imageUrl && (
                  <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <Image height={100} src={msg.imageUrl} />
                  </div>
                )}
                <Typography.Text type="secondary">
                  Envoyé le {format(new Date(msg.createdAt), 'dd/MM/yyyy à HH:mm')}
                </Typography.Text>
              </Space>
            </div>
            <span
              style={{
                display: 'block',
                width: 0,
                height: 0,
                ...(msg.role === 'user'
                  ? { borderLeft: '10px solid transparent', borderTop: '10px solid #adc6ff', float: 'right' }
                  : { borderRight: '10px solid transparent', borderTop: '10px solid #d6e4ff' }),
              }}
            />
          </span>
        ))}
        {['INITIALIZING', 'SUBMITTED'].includes(conversation?.status) && (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <Space
              size="middle"
              style={{
                display: 'flex',
                justifyContent: 'center',

                padding: 10,
                backgroundColor: '#597ef7',
                borderRadius: 20,
                width: 280,
              }}
            >
              <LoadingOutlined style={{ color: '#ffffff' }} />
              <Typography.Text style={{ color: '#ffffff' }}>En attente de la réponse de l'IA</Typography.Text>
            </Space>
          </span>
        )}
        {conversation?.status === 'ERRORED' && (
          <span style={{ display: 'flex', justifyContent: 'center' }}>
            <Space
              size="middle"
              style={{
                display: 'flex',
                justifyContent: 'center',

                padding: 10,
                paddingLeft: 20,
                paddingRight: 20,
                backgroundColor: '#f5222d',
                borderRadius: 20,
              }}
            >
              <WarningOutlined style={{ color: '#ffffff' }} />
              <Typography.Text style={{ color: '#ffffff' }}>{`La conversation a rencontré l'erreur suivante : ${
                conversation?.error || ''
              }`}</Typography.Text>
            </Space>
          </span>
        )}
      </Space>
    </Card>
  );
};

export default ConversationViewer;

import { Checkbox, Col, Collapse, Row, Space } from 'antd';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ImageProcessingOptions = memo(({ displayPrompt, onDisplayPromptChange, disabled }) => {
  const { control } = useFormContext();

  return (
    <Collapse collapsible="header" ghost>
      <Collapse.Panel header="Options de débogage">
        <Controller
          name="options"
          control={control}
          render={({ field }) => (
            <Checkbox.Group {...field} disabled={disabled}>
              <Row gutter={(24, 24)}>
                <Col span={12}>
                  <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                    <Checkbox
                      checked={displayPrompt}
                      onChange={() => {
                        onDisplayPromptChange(!displayPrompt);
                      }}
                    >
                      Afficher les prompts de chaque demande
                    </Checkbox>
                    <Checkbox value="skipAi">Génère une réponse automatique au lieu d'interroger l'IA</Checkbox>
                    <Checkbox value="uploadToCloudinary">Enregistre l'image générée sur Cloudinary</Checkbox>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                    <Checkbox value="skipQueue">Exécution directe de la tâche</Checkbox>
                    <Checkbox value="forcePriority">Force la priorité dans la file d'attente</Checkbox>
                    <Checkbox value="rejectFromQueue">Rejette la tâche à l'entrée de la file d'attente</Checkbox>
                    <Checkbox value="rejectFromThread">Rejette la tâche à la sortie de la file d'attente</Checkbox>
                  </Space>
                </Col>
              </Row>
            </Checkbox.Group>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

ImageProcessingOptions.displayName = 'ImageProcessingOptions';
export default ImageProcessingOptions;

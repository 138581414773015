import { memo, useState } from 'react';
import {
  Button,
  Table,
  Modal,
  Menu,
  Space,
  Popconfirm,
  Typography,
  Tooltip,
  Tag,
  Collapse,
  Checkbox,
  Image,
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  FileImageOutlined,
  KeyOutlined,
  MessageOutlined,
  RedoOutlined,
  TeamOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { format } from 'date-fns';
import { capitalize } from 'lodash';

import { aiRoutes, keysRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { checkAuthorization, sort } from '../../../shared/utils';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';

import Link from 'antd/lib/typography/Link';
import useSWR from 'swr';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const OUTCOME_LABELS = {
  SUCCESS: 'Réussite',
  ATTEMPT: 'Trop de tentatives',
  SECURITY: 'Rejet par la sécurité',
  MISC_ERROR: 'Erreur',
};

const OUTCOME_TAG_SELECTION = {
  SUCCESS: {
    label: OUTCOME_LABELS['SUCCESS'],
    icon: <CheckCircleOutlined />,
    color: 'success',
  },
  ATTEMPT: {
    label: OUTCOME_LABELS['ATTEMPT'],
    icon: <RedoOutlined />,
    color: 'warning',
  },
  SECURITY: {
    label: OUTCOME_LABELS['SECURITY'],
    icon: <ExclamationCircleOutlined />,
    color: 'default',
  },
  MISC_ERROR: {
    label: OUTCOME_LABELS['MISC_ERROR'],
    icon: <CloseCircleOutlined />,
    color: 'error',
  },
};

const JOB_AI_LABELS = {
  chatgpt: 'ChatGPT',
  mistral: 'Mistral AI',
};

const JOB_AI_TAG_SELECTION = {
  chatgpt: {
    label: JOB_AI_LABELS['chatgpt'],
    color: '#74AA9C',
  },
  mistral: {
    label: JOB_AI_LABELS['mistral'],
    color: '#ff7404',
  },
};

const INTERACTION_TYPES_LABELS = {
  CONVERSATION: 'Conversation',
  IMAGE_PROCESSING: "Traitement d'image",
};

const INTERACTION_TYPES_TAG_SELECTION = {
  CONVERSATION: {
    label: INTERACTION_TYPES_LABELS['CONVERSATION'],
    icon: <MessageOutlined />,
    color: 'gold',
  },
  IMAGE_PROCESSING: {
    label: INTERACTION_TYPES_LABELS['IMAGE_PROCESSING'],
    icon: <FileImageOutlined />,
    color: 'blue',
  },
};

const USER_TYPES_LABELS = {
  ADMIN: 'Personnel Arinfo',
  STUDENT: 'Apprenant',
};

const USER_TYPES_TAG_SELECTION = {
  ADMIN: {
    label: USER_TYPES_LABELS['ADMIN'],
    icon: <TeamOutlined />,
    color: '#315397',
  },
  STUDENT: {
    label: USER_TYPES_LABELS['STUDENT'],
    icon: <UserOutlined />,
    color: '#ed6d63',
  },
};

const MESSAGES_ROLE_SELECTION = {
  user: 'Utilisateur',
  assistant: 'Assistant IA',
  system: 'Prompt',
};

const ExpandableItem = memo(({ record, onOpenInteraction, onOpenPrompt }) => {
  const { data, isProcessing } = useSWR(record?.accessKey ? keysRoutes.default + '/' + record.accessKey : null, {
    revalidateOnReconnect: true,
    revalidateOnFocus: false,
  });

  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Space size="small">
          <Tag
            icon={INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].icon}
            color={INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].color}
          >
            {INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].label}
          </Tag>
          <Tag
            icon={USER_TYPES_TAG_SELECTION[record.interaction.userType].icon}
            color={USER_TYPES_TAG_SELECTION[record.interaction.userType].color}
          >
            {USER_TYPES_TAG_SELECTION[record.interaction.userType].label}
          </Tag>
          {isProcessing ? (
            <Tag icon={<KeyOutlined />} color="brown">
              Chargement...
            </Tag>
          ) : (
            <Tag icon={<KeyOutlined />} color="brown" style={{ opacity: !!data?.data?.name ? 1 : 0.5 }}>
              {data?.data?.name ?? "Aucune clé d'accès"}
            </Tag>
          )}
        </Space>
        <Space direction="vertical" size="small" style={{ display: 'flex' }}>
          <Button onClick={() => onOpenInteraction(record)} size="small" type="default">
            Voir l'interaction
          </Button>
          <Link onClick={() => onOpenPrompt(record.prompt)}>Voir le prompt</Link>
          <Collapse ghost>
            <Collapse.Panel header="Options de débogage">
              <Space direction="vertical" style={{ display: 'flex' }}>
                <Checkbox checked={record?.options?.skipQueue || false}>Exécution directe de la tâche</Checkbox>
                <Checkbox checked={record?.options?.skipAi || false}>
                  Génère une réponse automatique au lieu d'interroger l'IA
                </Checkbox>
                <Checkbox checked={record?.options?.forcePriority || false}>
                  Force la priorité dans la file d'attente
                </Checkbox>
                <Checkbox checked={record?.options?.rejectFromQueue || false}>
                  Rejette la tâche à l'entrée de la file d'attente
                </Checkbox>
                <Checkbox checked={record?.options?.rejectFromThread || false}>
                  Rejette la tâche à la sortie de la file d'attente
                </Checkbox>
              </Space>
            </Collapse.Panel>
          </Collapse>
        </Space>
      </Space>
    </>
  );
});

const InteractionModal = ({ data, visible, onClose }) => {
  return (
    <Modal visible={visible} onCancel={onClose} title="Messages de l'interaction" footer={false}>
      <Space direction="vertical" size="small" style={{ display: 'flex' }}>
        {(data?.messages || []).map((item) => (
          <>
            <Space size="small">
              <Typography.Text strong>{MESSAGES_ROLE_SELECTION[item.role]}</Typography.Text>
              <Typography.Text type="secondary">({item.createdAt})</Typography.Text>
            </Space>
            <Typography.Paragraph>{item.content}</Typography.Paragraph>
          </>
        ))}
        {(data?.images || []).map((item) => (
          <>
            <Space size="small">
              <Typography.Text strong>{MESSAGES_ROLE_SELECTION['assistant']}</Typography.Text>
              <Typography.Text type="secondary">({item.createdAt})</Typography.Text>
            </Space>
            {item?.prompt && (
              <Typography.Text italic type="secondary">
                {item.prompt}
              </Typography.Text>
            )}
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Image width={100} src={item.imageUrl} />
            </div>
          </>
        ))}
      </Space>
    </Modal>
  );
};

const PromptModal = ({ prompt, visible, onClose }) => {
  return (
    <Modal visible={visible} onCancel={onClose} title="Prompt de l'interaction" footer={false}>
      <Typography.Paragraph>
        <span dangerouslySetInnerHTML={{ __html: (prompt || '').replaceAll('\n', '<br/>') }} />
      </Typography.Paragraph>
    </Modal>
  );
};

const LogsAiList = ({ interactionLogs, isValidating, isProcessing, onProcessing, onMutate }) => {
  const { user } = useAuth();
  const [promptModalOpen, setPromptModalOpen] = useState(false);
  const [interactionModalOpen, setInteractionModalOpen] = useState(false);
  const { remove } = useFetch();

  const { data: applications, isValidating: isApplicationsValidating } = useSWR(`${aiRoutes.applications}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: activities, isValidating: isActivitiesValidating } = useSWR(`${aiRoutes.activities}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const deleteLog = async (logId) => {
    onProcessing(true);

    const results = await remove(`${aiRoutes.logs}/${logId}`);

    if (results.status === 200) {
      onMutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const columns = [
    {
      title: 'Résultat',
      dataIndex: 'outcome',
      key: 'outcome',
      width: 125,
      filters: [
        { text: OUTCOME_LABELS['SUCCESS'], value: 'SUCCESS' },
        { text: OUTCOME_LABELS['MISC_ERROR'], value: 'MISC_ERROR' },
      ],
      onFilter: (value, record) => record.outcome === value,
      render: (value, record) => (
        <Tooltip title={record?.error || null} destroyTooltipOnHide={{ keepParent: false }}>
          <Tag
            style={{ width: '100%', textAlign: 'center' }}
            color={OUTCOME_TAG_SELECTION[value]?.color || 'default'}
            icon={OUTCOME_TAG_SELECTION[value]?.icon || null}
          >
            {OUTCOME_TAG_SELECTION[value]?.label || ''}
          </Tag>
        </Tooltip>
      ),
    },
    {
      title: 'Application',
      dataIndex: ['interaction', 'activity', 'application', 'title'],
      key: 'application',
      filters: (applications?.data || []).map((item) => ({ text: item.title, value: item._id })),
      onFilter: (value, record) => record?.interaction?.activity?.application?._id === value,
    },
    {
      title: 'Activité',
      dataIndex: ['interaction', 'activity', 'title'],
      key: 'activity',
      filters: (activities?.data || []).map((item) => ({ text: item.title, value: item._id })),
      onFilter: (value, record) => record?.interaction?.activity?._id === value,
      render: (value, record) => (
        <>
          <Tag
            className="tag-icon-fix"
            icon={INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].icon}
            color={INTERACTION_TYPES_TAG_SELECTION[record.interaction.type].color}
          />
          <Typography.Text>{value}</Typography.Text>
        </>
      ),
    },
    {
      title: 'Utilisateur',
      dataIndex: ['interaction', 'user'],
      key: 'user',
      width: 300,
      sorter: (a, b) => sort(a, b, 'interaction.user.last_name'),
      sortDirections: ['ascend', 'descend'],
      render: (value, record) => (
        <>
          <Tag
            className="tag-icon-fix"
            icon={USER_TYPES_TAG_SELECTION[record.interaction.userType].icon}
            color={USER_TYPES_TAG_SELECTION[record.interaction.userType].color}
          />
          <Typography.Text>
            {capitalize(value.first_name)} {value.last_name.toUpperCase()}
          </Typography.Text>
        </>
      ),
    },
    {
      title: 'IA',
      dataIndex: 'ai',
      key: 'ai',
      width: 0,
      align: 'center',
      filters: [
        { text: JOB_AI_LABELS['chatgpt'], value: 'chatgpt' },
        { text: JOB_AI_LABELS['mistral'], value: 'mistral' },
      ],
      onFilter: (value, record) => record.ai.system === value,
      render: (value) => (
        <>
          {value?.release ? (
            <Tooltip title={value.release} destroyTooltipOnHide={{ keepParent: false }}>
              <Tag color={JOB_AI_TAG_SELECTION[value.system].color}>{JOB_AI_TAG_SELECTION[value.system].label}</Tag>
            </Tooltip>
          ) : (
            <>--</>
          )}
        </>
      ),
    },
    {
      title: 'Consommation',
      dataIndex: 'usage',
      width: 0,
      key: 'usage',
      sorter: (a, b) =>
        Number((a.usage.inputTokens || 0) + (a.usage.outputTokens || 0)) -
        Number((b.usage.inputTokens || 0) + (b.usage.outputTokens || 0)),
      sortDirections: ['ascend', 'descend'],
      render: (value) => (
        <>
          <Space size="small">
            <Tooltip title="Tokens de prompt" destroyTooltipOnHide={{ keepParent: false }}>
              <Tag icon={<ArrowUpOutlined />}>{value?.inputTokens || 0}</Tag>
            </Tooltip>
            <Tooltip title="Tokens de complétion" destroyTooltipOnHide={{ keepParent: false }}>
              <Tag icon={<ArrowDownOutlined />}>{value?.outputTokens || 0}</Tag>
            </Tooltip>
            <Tooltip title="Images générées" destroyTooltipOnHide={{ keepParent: false }}>
              <Tag icon={<FileImageOutlined />}>{value?.outputImages?.length || 0}</Tag>
            </Tooltip>
          </Space>
        </>
      ),
    },
    {
      title: 'Tentative',
      dataIndex: 'attempt',
      key: 'attempt',
      width: 0,
      align: 'center',
      sorter: (a, b) => Number(a.attempt || 0) - Number(b.attempt || 0),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: 'Enregistré le',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 200,
      align: 'center',
      render: (record) => format(new Date(record), 'dd/MM/yyyy à HH:mm'),
      sorter: (a, b) => sort(a, b, 'createdAt'),
      sortDirections: ['ascend', 'descend'],
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Popconfirm {...popconfirmProps} onConfirm={() => deleteLog(record._id)}>
          <Button
            icon={<DeleteOutlined />}
            disabled={isProcessing || !checkAuthorization(user, 'ai', 'logs', 'delete')}
          />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <InteractionModal
        data={interactionModalOpen}
        visible={!!interactionModalOpen}
        onClose={() => setInteractionModalOpen(false)}
      />
      <PromptModal prompt={promptModalOpen} visible={!!promptModalOpen} onClose={() => setPromptModalOpen(false)} />
      <Table
        loading={!interactionLogs || isValidating || isProcessing || isApplicationsValidating || isActivitiesValidating}
        dataSource={interactionLogs}
        columns={columns}
        bordered
        size="small"
        rowKey={(row) => row._id}
        pagination={tablePagination(interactionLogs)}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandableItem
              {...{ record }}
              onOpenInteraction={setInteractionModalOpen}
              onOpenPrompt={setPromptModalOpen}
            />
          ),
        }}
      />
    </>
  );
};

export default LogsAiList;

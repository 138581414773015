import { Card, Form, Button, Input, Upload, Typography, message, Col, Row, Select, Divider, Space } from 'antd';
import { Link, Prompt, useHistory } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useForm, Controller, useFormState, useWatch, FormProvider } from 'react-hook-form';
import useSWR from 'swr';

import useFetch from '../../../hooks/useFetch';
import { aiRoutes } from '../../../lib/routes';
import { getImageUrl, getSignature } from '../../../lib/cloudinary';
import { useAuth } from '../../../authContext';
import { checkAuthorization, sort } from '../../../shared/utils';

import ModulePreviewModal from '../components/ModulePreviewModal';
import { PlusOutlined } from '@ant-design/icons';

const { Item } = Form;
const { TextArea } = Input;
const { Dragger } = Upload;
const { Paragraph } = Typography;

const PersonaCreate = () => {
  const { user } = useAuth();
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [newCategoryName, setNewCategoryName] = useState('');
  const [openCategorySelect, setOpenCategorySelect] = useState(false);
  const [modulePreviewModal, setModulePreviewModal] = useState(false);
  const methods = useForm();
  const { handleSubmit, control, setError, formState } = methods;

  const { data: personas, isValidating } = useSWR(aiRoutes.personas);
  const categories =
    personas?.data?.reduce((acc, curr) => {
      if (!acc.some((item) => item === curr?.category) && !!curr?.category) {
        acc.push(curr.category);
      }
      return acc;
    }, []) || [];

  const image = useWatch({ control, name: 'image' });
  const imageUrl = useMemo(() => (image ? getImageUrl(image) : null), [image]);
  const { errors } = useFormState({ control });
  const { post } = useFetch();
  const { token } = useAuth();
  const history = useHistory();

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(aiRoutes.personas, JSON.stringify(form));

    if (results.status === 201) {
      setIsBlocking(false);
      history.push('/ia/personas');
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <ModulePreviewModal
        isOpen={!!modulePreviewModal}
        input={modulePreviewModal}
        onClose={() => setModulePreviewModal(false)}
      />
      <FormProvider {...methods}>
        <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
          <Paragraph type="secondary">
            <blockquote style={{ marginTop: 0 }}>
              <ul>
                <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              </ul>
            </blockquote>
          </Paragraph>
          <Row gutter={[24, 24]} align="bottom">
            <Col span={9}>
              <Item validateStatus={errors?.name?.message && 'error'} help={errors?.name?.message} label="Nom" required>
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input {...field} placeholder="Entrez le nom affiché pour ce persona" disabled={isProcessing} />
                  )}
                />
              </Item>
              <Item
                validateStatus={errors?.category?.message && 'error'}
                help={errors?.category?.message}
                label="Catégorie"
              >
                <Controller
                  control={control}
                  name="category"
                  render={({ field }) => (
                    <Select
                      {...field}
                      open={openCategorySelect}
                      onDropdownVisibleChange={(visible) => setOpenCategorySelect(visible)}
                      allowClear
                      loading={isValidating}
                      placeholder="Ce persona n'est lié à aucune catégorie"
                      options={categories.map((item) => ({
                        label: item,
                        value: item,
                      }))}
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          {checkAuthorization(user, 'ai', 'personas', 'createCategory') && (
                            <>
                              <Divider
                                style={{
                                  margin: '8px 0',
                                }}
                              />
                              <Space
                                style={{
                                  padding: '0 8px 4px',
                                }}
                              >
                                <Input
                                  placeholder="Entrez le nom de la catégorie"
                                  value={newCategoryName}
                                  onChange={(event) => setNewCategoryName(event.target.value)}
                                />
                                <Button
                                  type="text"
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    field.onChange(newCategoryName);
                                    setOpenCategorySelect(false);
                                    setNewCategoryName('');
                                  }}
                                >
                                  Nouvelle catégorie
                                </Button>
                              </Space>
                            </>
                          )}
                        </>
                      )}
                    />
                  )}
                />
              </Item>
            </Col>
            <Col span={3}>
              <Item
                label="Image"
                validateStatus={errors?.image && 'error'}
                help={errors?.image?.message}
                extra="Poids maximal : 1Mo"
              >
                <Controller
                  control={control}
                  name="image"
                  render={({ field }) => {
                    return (
                      <Dragger
                        action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`}
                        name={field.name}
                        maxCount={1}
                        showUploadList={false}
                        data={(file) => getSignature(file, token)}
                        height={100}
                        disabled={isProcessing}
                        accept="image/*"
                        style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                        beforeUpload={(file) => {
                          if (!file?.type?.startsWith('image/')) {
                            message.error("Format d'image invalide");
                            return false;
                          }

                          if (!file?.size || file.size / 1024 / 1024 > 1) {
                            message.error('Fichier trop lourd (taille maximale autorisée : 1Mo)');
                            return false;
                          }
                        }}
                        onChange={({ file }) => {
                          if (file.status === 'done') {
                            field.onChange(file.response.public_id);
                          }
                        }}
                      >
                        {imageUrl ? (
                          <img
                            style={{
                              maxWidth: 'calc(100% - 16px)',
                              maxHeight: 60,
                              display: 'block',
                              margin: '0 auto',
                            }}
                            src={imageUrl}
                            alt=""
                          />
                        ) : (
                          <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                        )}
                      </Dragger>
                    );
                  }}
                />
              </Item>
            </Col>
          </Row>
          <Row gutter={[24, 24]} align="bottom">
            <Col span={12}>
              <Item
                validateStatus={errors?.description?.message && 'error'}
                help={errors?.description?.message}
                label="Description"
                tooltip="Informations fournies à l'agent IA pour contextualiser le persona. Essentiel pour une discussion sur un produit, une entreprise, etc."
              >
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      placeholder="Décrire toutes les informations sur le persona utiles pour l'agent IA"
                      autoSize={{ minRows: 2, maxRows: 15 }}
                      disabled={isProcessing}
                    />
                  )}
                />
              </Item>
              <Item
                validateStatus={errors?.behaviour?.message && 'error'}
                help={errors?.behaviour?.message}
                label="Comportement"
                tooltip="Indique à l'agent IA comment il est censé se comporter dans le rôle de ce persona. Essentiel dans un jeu de rôle"
              >
                <Controller
                  control={control}
                  name="behaviour"
                  render={({ field }) => (
                    <TextArea
                      {...field}
                      placeholder="Décrire le comportement de l'agent IA dans le rôle de ce persona"
                      autoSize={{ minRows: 2, maxRows: 15 }}
                      disabled={isProcessing}
                    />
                  )}
                />
              </Item>
            </Col>
          </Row>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button className="gray-text" disabled={isProcessing}>
              <Link to="/ia/personas">Annuler</Link>
            </Button>
            <Button type="primary" htmlType="submit" loading={isProcessing}>
              Enregistrer
            </Button>
          </div>
        </Form>
      </FormProvider>
    </Card>
  );
};

export default PersonaCreate;

import { Col, Form, Input, Row, Upload } from 'antd';
import { useFormContext, useFormState, Controller } from 'react-hook-form';
import { memo } from 'react';

import { useAuth } from '../../../../../authContext';
import { getImageUrl, getSignature } from '../../../../../lib/cloudinary';

const ConversationInputs = memo(({ conversation, activity, isProcessing }) => {
  const { control, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const image = watch('image');
  const options = watch('options');
  const { token } = useAuth();

  return (
    <Row gutter={(24, 24)}>
      <Col span={18}>
        <Form.Item
          label="Message"
          validateStatus={errors?.message?.message && 'error'}
          help={errors?.message?.message}
          required={!!conversation?._id}
        >
          <Controller
            control={control}
            name="message"
            render={({ field }) => (
              <Input.TextArea
                {...field}
                autoSize
                style={{ minHeight: 100 }}
                disabled={
                  isProcessing ||
                  !activity ||
                  ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.status) ||
                  (conversation?.status === 'COMPLETED' && !options?.includes('forceContinue'))
                }
              />
            )}
          />
        </Form.Item>
      </Col>
      <Col span={6}>
        <Form.Item
          label="Image"
          validateStatus={errors?.messages?.message && 'error'}
          help={errors?.messages?.message}
          extra="Poids maximal : 1Mo"
        >
          <Controller
            control={control}
            name="image"
            render={({ field }) => {
              return (
                <Upload.Dragger
                  action={`https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`}
                  name={field.name}
                  maxCount={1}
                  showUploadList={false}
                  data={(file) => getSignature(file, token)}
                  height={100}
                  disabled={
                    isProcessing ||
                    !activity ||
                    ['INITIALIZING', 'SUBMITTED', 'ERRORED'].includes(conversation?.status) ||
                    (conversation?.status === 'COMPLETED' && !options?.includes('forceContinue'))
                  }
                  accept="image/*"
                  style={{ border: errors?.image?.message && '1px solid #ff4d4f' }}
                  beforeUpload={(file) => {
                    if (!file?.type?.startsWith('image/')) {
                      message.error("Format d'image invalide");
                      return false;
                    }

                    if (!file?.size || file.size / 1024 / 1024 > 1) {
                      message.error('Fichier trop lourd (taille maximale autorisée : 1Mo)');
                      return false;
                    }
                  }}
                  onChange={({ file }) => {
                    if (file.status === 'done') {
                      field.onChange(getImageUrl(file.response.public_id));
                    }
                  }}
                >
                  {image ? (
                    <img
                      style={{
                        maxWidth: 'calc(100% - 16px)',
                        maxHeight: 60,
                        display: 'block',
                        margin: '0 auto',
                      }}
                      src={image}
                      alt=""
                    />
                  ) : (
                    <p className="ant-upload-hint">Sélectionnez ou déposez une image</p>
                  )}
                </Upload.Dragger>
              );
            }}
          />
        </Form.Item>
      </Col>
    </Row>
  );
});

ConversationInputs.displayName = 'ConversationInputs';
export default ConversationInputs;

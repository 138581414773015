import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Form, Typography, Button, Space, Alert } from 'antd';
import NonFormaoStudentForm from '../components/NonFormaoStudentForm';
import FormaoStudentForm from '../components/FormaoStudentForm';

const StudentStep = ({ disabled, setFormsOptions, toggleShowAllForms, formattedForms, isEditing }) => {
  const { setValue, watch } = useFormContext();
  const [isFormaoUser, setIsFormaoUser] = useState();
  const studentId = watch('studentId');
  const studentEmail = watch('studentEmail');

  useEffect(() => {
    if (!isEditing && studentEmail) {
      setIsFormaoUser(!!studentId);
    }
  }, []);

  const handleFormaoUser = (value) => {
    if (value !== isFormaoUser) {
      setValue('studentId', null);
      setValue('studentFormationId', null);
      setValue('studentGroup', null);
      setValue('studentFirstName', null);
      setValue('studentLastName', null);
      setValue('studentEmail', null);
      setValue('studentFormation', null);
      setValue('studentPhone', null);
      setValue('studentAgency', null);
      setValue('studentFormationDates', null);
      setValue('studentFormer', null);
      setValue('referent', null);

      setIsFormaoUser(value);
    }
  };

  const childProps = {
    disabled,
    setFormsOptions,
    toggleShowAllForms,
    formattedForms,
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Typography.Paragraph type="secondary">
        <blockquote style={{ marginTop: 0 }}>
          <ul>
            <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
          </ul>
        </blockquote>
      </Typography.Paragraph>

      {isEditing ? (
        studentId ? (
          <Space size="middle" direction="vertical" style={{ width: '100%' }}>
            <Alert
              type="info"
              showIcon
              message="Il n'est pas possible de modifier les informations d'un utilisateur formao depuis cette interface"
            />
            <FormaoStudentForm {...childProps} disabled={true} />
          </Space>
        ) : (
          <NonFormaoStudentForm {...childProps} isEditing={true} />
        )
      ) : (
        <>
          <Form.Item label="Stagiaire Formao" required>
            <Space>
              <Button type={isFormaoUser === true && 'primary'} onClick={() => handleFormaoUser(true)}>
                Oui
              </Button>
              <Button type={isFormaoUser === false && 'primary'} onClick={() => handleFormaoUser(false)}>
                Non
              </Button>
            </Space>
          </Form.Item>
          {isFormaoUser === true && (
            <>
              <FormaoStudentForm {...childProps} />
              <Alert
                type="warning"
                message="Attention ! Une fois la création terminée vous ne pourrez plus modifier ces informations."
                showIcon
              />
            </>
          )}
          {isFormaoUser === false && <NonFormaoStudentForm {...childProps} isEditing={false} />}
        </>
      )}
    </Space>
  );
};

export default StudentStep;

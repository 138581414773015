import { Collapse, Divider, Form, List, Select, Space, Typography } from 'antd';
import { memo, useState } from 'react';
import { Controller, useFormContext, useFormState } from 'react-hook-form';

import RandomNumberVariable from './RandomNumberVariable';
import ElementsListVariable from './ElementsListVariable';
import UserFieldVariable from './UserFieldVariable';
import PersonaVariable from './PersonaVariable';
import UserInputVariable from './UserInputVariable';

const VARIABLE_TYPE_SELECTION = [
  {
    label: 'Profil utilisateur',
    value: 'USER_PROFILE',
  },
  {
    label: 'Persona(s)',
    value: 'PERSONAS',
  },
  {
    label: "Liste d'éléments",
    value: 'LIST',
  },
  {
    label: 'Nombre aléatoire',
    value: 'RANDOM_NUMBER',
  },
  {
    label: 'Entrée utilisateur',
    value: 'USER_INPUT',
  },
];

const ModuleVariableListItem = memo(({ item, index, processing }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const [variableType, setVariableType] = useState(item.type);

  return (
    <List.Item key={`variable-${index}`} style={{ padding: 0 }}>
      <Collapse ghost style={{ width: '100%' }}>
        <Collapse.Panel
          header={
            <Controller
              name={`variables.[${index}].key`}
              control={control}
              render={({ field }) => <Typography.Text>{field.value}</Typography.Text>}
            />
          }
        >
          <Space direction="vertical" style={{ display: 'flex' }}>
            <Space size="middle">
              <Form.Item
                label="Type de variable"
                required
                validateStatus={errors?.variables?.[index]?.type?.message && 'error'}
                help={errors?.variables?.[index]?.type?.message}
                style={{ marginBottom: 0 }}
              >
                <Controller
                  name={`variables.[${index}].type`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      style={{ width: '200px' }}
                      disabled={processing}
                      onChange={(value) => {
                        field.onChange(value);
                        setVariableType(value);
                      }}
                      options={VARIABLE_TYPE_SELECTION}
                    />
                  )}
                />
              </Form.Item>
            </Space>
            {variableType !== '' && (
              <Divider
                style={{
                  margin: '8px 0',
                }}
              />
            )}
            {variableType === 'RANDOM_NUMBER' && <RandomNumberVariable index={index} disabled={processing} />}
            {variableType === 'LIST' && <ElementsListVariable index={index} disabled={processing} />}
            {variableType === 'USER_PROFILE' && <UserFieldVariable index={index} disabled={processing} />}
            {variableType === 'USER_INPUT' && <UserInputVariable index={index} disabled={processing} />}
            {variableType === 'PERSONAS' && <PersonaVariable index={index} disabled={processing} />}
          </Space>
        </Collapse.Panel>
      </Collapse>
    </List.Item>
  );
});

ModuleVariableListItem.displayName = 'ModuleVariableListItem';
export default ModuleVariableListItem;

import { Checkbox, Col, Collapse, Row, Space } from 'antd';
import { memo } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

const ConversationOptions = memo(({ displayPrompt, onDisplayPromptChange, disabled, hidden }) => {
  const { control } = useFormContext();

  return (
    <Collapse collapsible="header" ghost>
      <Collapse.Panel header="Options de débogage">
        <Controller
          name="options"
          control={control}
          render={({ field }) => (
            <Checkbox.Group {...field} disabled={disabled}>
              <Row gutter={(24, 24)}>
                <Col span={12}>
                  <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                    <Checkbox
                      checked={displayPrompt}
                      onChange={() => {
                        onDisplayPromptChange(!displayPrompt);
                      }}
                    >
                      Afficher le prompt au début de la conversation
                    </Checkbox>
                    <Checkbox disabled={hidden} value="skipAi">
                      Génère une réponse automatique au lieu d'interroger l'IA
                    </Checkbox>
                    <Checkbox disabled={hidden} value="forceContinue">
                      Force l'IA à continuer la discussion
                    </Checkbox>
                  </Space>
                </Col>
                <Col span={12}>
                  <Space size="small" direction="vertical" style={{ display: 'flex' }}>
                    <Checkbox disabled={hidden} value="skipQueue">
                      Exécution directe de la tâche
                    </Checkbox>
                    <Checkbox disabled={hidden} value="forcePriority">
                      Force la priorité dans la file d'attente
                    </Checkbox>
                    <Checkbox disabled={hidden} value="rejectFromQueue">
                      Rejette la tâche à l'entrée de la file d'attente
                    </Checkbox>
                    <Checkbox disabled={hidden} value="rejectFromThread">
                      Rejette la tâche à la sortie de la file d'attente
                    </Checkbox>
                  </Space>
                </Col>
              </Row>
            </Checkbox.Group>
          )}
        />
      </Collapse.Panel>
    </Collapse>
  );
});

ConversationOptions.displayName = 'ConversationOptions';
export default ConversationOptions;

import { useFormContext, Controller, useFormState, useWatch, useFieldArray } from 'react-hook-form';
import { Form, Row, Col, DatePicker, Select, Checkbox, Typography, Space, Divider, Button, Alert, Radio } from 'antd';
import moment from 'moment';
import useSWR from 'swr';
import { sort } from '../../../../shared/utils';
import { formationRoutes, userRoutes, agencyRoutes, groupRoutes } from '../../../../lib/routes';
import StudentEditProgram from './StudentEditProgram';

const { Item } = Form;
const { Title } = Typography;

const FormationForm = ({ index, data, total, disabled, onDelete }) => {
  const { control, setValue, clearErrors } = useFormContext();
  const { errors } = useFormState({ control });
  const isCustom = useWatch({ control, name: `formations.${index}.custom` });
  const extraDate = useWatch({ control, name: 'extra' });
  const fromGroup = useWatch({ control, name: `formations.${index}.group` });

  const handleFilter = (inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase());

  const handleGroup = (value, onChange) => {
    const groupDetails = data.groups.data.find((item) => item._id === value);

    if (groupDetails) {
      setValue(`formations.${index}.formation`, groupDetails?.formation?._id);
      setValue(`formations.${index}.startAt`, moment(groupDetails.startAt));
      setValue(`formations.${index}.endAt`, moment(groupDetails?.endAt));
      setValue(`formations.${index}.referent`, groupDetails?.referent?._id);
      setValue(`formations.${index}.realizationMethod`, groupDetails?.realizationMethod || null);
      setValue(`formations.${index}.formationDomain`, groupDetails?.formationDomain || null);

      clearErrors([
        `formations.${index}.formation`,
        `formations.${index}.startAt`,
        `formations.${index}.endAt`,
        `formations.${index}.referent`,
        `formations.${index}.realizationMethod`,
        `formations.${index}.formationDomain`,
      ]);

      onChange(value);
    } else {
      if (!value) {
        setValue(`formations.${index}.formation`, null);
        setValue(`formations.${index}.startAt`, null);
        setValue(`formations.${index}.endAt`, null);
        setValue(`formations.${index}.referent`, null);
        setValue(`formations.${index}.realizationMethod`, null);
        setValue(`formations.${index}.formationDomain`, null);
      }
      onChange(null);
    }
  };

  return (
    <>
      <Space style={{ marginBottom: 20 }}>
        <Title level={4} style={{ marginBottom: 0 }}>
          Formation {index + 1}
        </Title>
        <Button onClick={onDelete}>Supprimer</Button>
      </Space>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item extra="En cochant cette case vous pourrez définir un programme personnalisé pour cet apprenant">
            <Controller
              control={control}
              name={`formations.${index}.custom`}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={disabled}
                  onChange={(e) => {
                    if (extraDate > 9) setValue('extra', null);
                    return field.onChange(e.target.checked);
                  }}
                >
                  Entrée/sortie
                </Checkbox>
              )}
            />
            {isCustom && <StudentEditProgram index={index} />}
          </Item>
        </Col>
      </Row>
      {!isCustom && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Groupe"
              validateStatus={errors?.formations?.[index]?.group?.message && 'error'}
              help={errors?.formations?.[index]?.group?.message}
              extra="Sélectionner un groupe préremplira le formulaire"
            >
              <Controller
                control={control}
                name={`formations.${index}.group`}
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    onChange={(value) => handleGroup(value, field.onChange)}
                    placeholder="Sélectionnez dans la liste"
                    disabled={!data.groups.data || data.groups.isValidating || disabled}
                    loading={data.groups.isValidating}
                    options={
                      data.groups?.data
                        .sort((a, b) => sort(a, b, 'name'))
                        .map((group) => ({
                          label: group.name,
                          value: group._id,
                        })) || []
                    }
                    allowClear
                  />
                )}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Formation"
              required
              validateStatus={errors?.formations?.[index]?.formation?.message && 'error'}
              help={errors?.formations?.[index]?.formation?.message}
              extra={fromGroup && "La formation est bloquée tant qu'un groupe est sélectionné"}
            >
              <Controller
                control={control}
                name={`formations.${index}.formation`}
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    placeholder="Sélectionnez dans la liste"
                    disabled={!data.formations.data || data.formations.isValidating || disabled || fromGroup}
                    loading={data.formations.isValidating}
                    options={
                      data.formations.data
                        .sort((a, b) => sort(a, b, 'title'))
                        .map((program) => ({
                          label: program.title,
                          value: program._id,
                        })) || []
                    }
                  />
                )}
              />
            </Item>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Formateur référent"
            required
            validateStatus={errors?.formations?.[index]?.referent?.message && 'error'}
            help={errors?.formations?.[index]?.referent?.message}
          >
            <Controller
              control={control}
              name={`formations.${index}.referent`}
              render={({ field }) => (
                <Select
                  {...field}
                  filterOption={handleFilter}
                  showSearch
                  placeholder="Sélectionnez dans la liste"
                  disabled={!data.users.data || data.users.isValidating || disabled}
                  loading={data.users.isValidating}
                  options={(data?.users?.data || [])
                    .map((user) => ({
                      label: `${user.last_name.toUpperCase()} ${
                        user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                      }`,
                      value: user._id,
                      disabled: user.disabled,
                    }))
                    .sort((a, b) => sort(a, b, 'label'))}
                />
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Centre"
            required
            validateStatus={errors?.formations?.[index]?.agency?.message && 'error'}
            help={errors?.formations?.[index]?.agency?.message}
          >
            <Controller
              control={control}
              name={`formations.${index}.agency`}
              render={({ field }) => (
                <Select
                  {...field}
                  filterOption={handleFilter}
                  showSearch
                  placeholder="Sélectionnez dans la liste"
                  disabled={!data.agencies.data || data.agencies.isAgenciesValidating || disabled}
                  loading={data.agencies.isAgenciesValidating}
                  options={data.agencies.data
                    .map((agency) => ({
                      label: agency.city,
                      value: agency._id,
                    }))
                    .sort((a, b) => sort(a, b, 'label'))}
                />
              )}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Date de début"
            required
            validateStatus={errors?.formations?.[index]?.startAt?.message && 'error'}
            help={errors?.formations?.[index]?.startAt?.message}
          >
            <Controller
              control={control}
              name={`formations.${index}.startAt`}
              render={({ field }) => (
                <DatePicker {...field} format="DD/MM/YYYY" placeholder="Sélectionnez une date" disabled={disabled} />
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Date de fin"
            required
            validateStatus={errors?.formations?.[index]?.endAt?.message && 'error'}
            help={errors?.formations?.[index]?.endAt?.message}
            extra={`L'accès à Formao sera bloqué ${
              isCustom ? '1' : '3'
            } mois après cette date mais vous pouvez étendre cette durée en dessous`}
          >
            <Controller
              control={control}
              name={`formations.${index}.endAt`}
              render={({ field }) => (
                <DatePicker {...field} format="DD/MM/YYYY" placeholder="Sélectionnez une date" disabled={disabled} />
              )}
            />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Modalité de réalisation"
            validateStatus={errors?.formations?.[index]?.realizationMethod?.message && 'error'}
            help={errors?.formations?.[index]?.realizationMethod?.message}
            required
          >
            <Controller
              name={`formations.${index}.realizationMethod`}
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space direction="vertical">
                    <Radio
                      className={errors?.formations?.[index]?.realizationMethod?.message && 'radio-danger'}
                      value="PRESENTIEL"
                    >
                      En présentiel
                    </Radio>
                    <Radio
                      className={errors?.formations?.[index]?.realizationMethod?.message && 'radio-danger'}
                      value="DISTANCIEL"
                    >
                      En télé-présentiel
                    </Radio>
                    <Radio
                      className={errors?.formations?.[index]?.realizationMethod?.message && 'radio-danger'}
                      value="MIXTE"
                    >
                      Mixte
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Domaine de formation"
            validateStatus={errors?.formations?.[index]?.formationDomain?.message && 'error'}
            help={errors?.formations?.[index]?.formationDomain?.message}
            required
          >
            <Controller
              name={`formations.${index}.formationDomain`}
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space direction="vertical">
                    <Radio
                      className={errors?.formations?.[index]?.formationDomain?.message && 'radio-danger'}
                      value="BATIMENT"
                    >
                      Bâtiment, modélisation (NSF 230)
                    </Radio>
                    <Radio
                      className={errors?.formations?.[index]?.formationDomain?.message && 'radio-danger'}
                      value="IMPRESSION"
                    >
                      Impression, édition, graphisme (NSF 322)
                    </Radio>
                    <Radio
                      className={errors?.formations?.[index]?.formationDomain?.message && 'radio-danger'}
                      value="INFORMATIQUE"
                    >
                      Informatique, développement (NSF 326)
                    </Radio>
                    <Radio
                      className={errors?.formations?.[index]?.formationDomain?.message && 'radio-danger'}
                      value="COMMUNICATION"
                    >
                      Communication digitale et web (NSF 320)
                    </Radio>
                    <Radio
                      className={errors?.formations?.[index]?.formationDomain?.message && 'radio-danger'}
                      value="IMAGE"
                    >
                      Techniques image, son et vidéo (NSF 323)
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} />
        <Col span={12}>
          <Item
            label="Ajouter une durée d'accès"
            validateStatus={errors?.formations?.[index]?.extra?.message && 'error'}
            help={errors?.formations?.[index]?.extra?.message}
          >
            <Controller
              control={control}
              name={`formations.${index}.extra`}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  disabled={disabled}
                  allowClear
                  options={[
                    { label: '+1 mois', value: 1 },
                    { label: '+2 mois', value: 2 },
                    { label: '+3 mois', value: 3 },
                    { label: '+4 mois', value: 4 },
                    { label: '+5 mois', value: 5 },
                    { label: '+6 mois', value: 6 },
                    { label: '+7 mois', value: 7 },
                    { label: '+8 mois', value: 8 },
                    { label: '+9 mois', value: 9 },
                    ...(isCustom
                      ? [
                          { label: '+10 mois', value: 10 },
                          { label: '+11 mois', value: 11 },
                        ]
                      : []),
                  ]}
                />
              )}
            />
          </Item>
        </Col>
      </Row>
      {index + 1 < total && <Divider />}
    </>
  );
};

const StudentEditFormations = ({ disabled }) => {
  const { control } = useFormContext();
  const { errors } = useFormState({ control });
  const { fields: formations, append, remove } = useFieldArray({ control, name: 'formations' });
  const { data, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: users, isValidating: isUsersValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: groups, isValidating: isGroupsValidating } = useSWR(groupRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  return (
    <>
      {errors?.formations?.message && (
        <Alert style={{ marginBottom: 20 }} type="error" showIcon message={errors?.formations?.message} />
      )}
      <Space direction="vertical" style={{ width: '100%', marginBottom: 20 }}>
        {formations.map((formation, index) => (
          <FormationForm
            key={formation._id || formation.id}
            index={index}
            total={formations.length}
            disabled={disabled}
            data={{
              formations: { data: data?.data || [], isValidating: isFormationsValidating },
              users: { data: users?.data || [], isValidating: isUsersValidating },
              agencies: { data: agencies?.data || [], isValidating: isAgenciesValidating },
              groups: { data: groups?.data || [], isValidating: isGroupsValidating },
            }}
            onDelete={() => remove(index)}
          />
        ))}
      </Space>
      <Button
        type="primary"
        block
        style={{ marginBottom: 20 }}
        onClick={() =>
          append({
            formation: null,
            custom: false,
            startAt: null,
            endAt: null,
            extra: null,
            isNew: true,
          })
        }
      >
        Ajouter une formation
      </Button>
    </>
  );
};

export default StudentEditFormations;

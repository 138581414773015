import { useState, useEffect } from 'react';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { Card, Form, Typography, Button, message, Row, Col, Alert, Select, DatePicker, Radio, Space } from 'antd';
import { Link, useHistory, Prompt } from 'react-router-dom';
import useSWR from 'swr';
import useFetch from '../../../hooks/useFetch';
import { sort } from '../../../shared/utils';
import { groupRoutes, userRoutes, agencyRoutes, formationRoutes } from '../../../lib/routes';
import { useAuth } from '../../../authContext';

const { Item } = Form;

const GroupCreate = () => {
  const [isBlocking, setIsBlocking] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const { user } = useAuth();
  const { handleSubmit, control, setError, formState } = useForm({ defaultValues: { referent: user._id } });
  const { errors } = useFormState({ control });
  const { post } = useFetch();
  const history = useHistory();
  const { data: users, isValidating: isUsersValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  useEffect(() => {
    if (formState.isDirty) {
      setIsBlocking(true);
    }
  }, [formState.isDirty]);

  const handleFilter = (inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase());

  const onSubmit = async (form) => {
    setIsProcessing(true);

    const results = await post(groupRoutes.default, JSON.stringify(form));

    if (results.status === 201) {
      const id = results.data._id;

      setIsBlocking(false);
      history.push(`/formao/sessions/modifier-une-session/${id}`);
    } else {
      if (results.message) {
        message.error(results.message);
      } else {
        Object.entries(results.errors).forEach(([key, value]) => {
          setError(key, { type: 'manual', message: value });
        });
      }
    }

    setIsProcessing(false);
  };

  return (
    <Card>
      <Prompt
        when={isBlocking}
        message="Vous n'avez pas sauvegardé vos modifications, voulez-vous vraiment quitter cette page ?"
      />
      <Form noValidate layout="vertical" onFinish={handleSubmit(onSubmit)}>
        <Typography.Paragraph type="secondary">
          <blockquote style={{ marginTop: 0 }}>
            <ul>
              <li>Les champs marqués d'un astérisque (*) sont obligatoires</li>
              <li>Vous pourrez sélectionner la formation ainsi que les apprenants après la création</li>
            </ul>
          </blockquote>
        </Typography.Paragraph>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Formation"
              required
              validateStatus={errors?.formation?.message && 'error'}
              help={errors?.formation?.message}
            >
              <Controller
                control={control}
                name="formation"
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    placeholder="Sélectionnez dans la liste"
                    disabled={!formations || isFormationsValidating || isProcessing}
                    loading={isFormationsValidating}
                    options={
                      formations?.data
                        .sort((a, b) => sort(a, b, 'title'))
                        .map((formation) => ({ label: formation.title, value: formation._id })) || []
                    }
                  />
                )}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Centre"
              required
              validateStatus={errors?.agency?.message && 'error'}
              help={errors?.agency?.message}
            >
              <Controller
                control={control}
                name="agency"
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    placeholder="Sélectionnez dans la liste"
                    disabled={!agencies || isAgenciesValidating || isProcessing}
                    loading={isAgenciesValidating}
                    options={[{ label: 'Distanciel', value: 'DISTANCIEL' }]
                      .concat(agencies?.data.map((agency) => ({ label: agency.city, value: agency._id })) || [])
                      .sort((a, b) => sort(a, b, 'label'))}
                  />
                )}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Formateur référent"
              required
              validateStatus={errors?.referent?.message && 'error'}
              help={errors?.referent?.message}
            >
              <Controller
                control={control}
                name="referent"
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    placeholder="Sélectionnez dans la liste"
                    disabled={!users || isUsersValidating || isProcessing}
                    loading={isUsersValidating}
                    options={
                      users?.data
                        .map((user) => ({
                          label: `${user.last_name.toUpperCase()} ${
                            user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                          }`,
                          value: user._id,
                        }))
                        .sort((a, b) => sort(a, b, 'label')) || []
                    }
                  />
                )}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Type de session"
              required
              validateStatus={errors?.type?.message && 'error'}
              help={errors?.type?.message}
            >
              <Controller
                control={control}
                name="type"
                render={({ field }) => (
                  <Radio.Group {...field} disabled={isProcessing}>
                    <Radio value="TEMPS CONTINU">Temps continu</Radio>
                    <Radio value="ALTERNANCE">Alternance</Radio>
                  </Radio.Group>
                )}
              />
            </Item>
          </Col>
        </Row>
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Date d'entrée"
              required
              validateStatus={errors?.startAt?.message && 'error'}
              help={errors?.startAt?.message}
            >
              <Controller
                control={control}
                name="startAt"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    format="DD/MM/YYYY"
                    placeholder="Sélectionnez une date"
                    disabled={isProcessing}
                  />
                )}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Date de fin"
              required
              validateStatus={errors?.endAt?.message && 'error'}
              help={errors?.endAt?.message}
              extra="L'accès à Formao sera bloqué 1 mois après cette date"
            >
              <Controller
                control={control}
                name="endAt"
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    format="DD/MM/YYYY"
                    placeholder="Sélectionnez une date"
                    disabled={isProcessing}
                  />
                )}
              />
            </Item>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Modalité de réalisation"
              validateStatus={errors?.realizationMethod?.message && 'error'}
              help={errors?.realizationMethod?.message}
              required
            >
              <Controller
                name="realizationMethod"
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    <Space direction="vertical">
                      <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="PRESENTIEL">
                        En présentiel
                      </Radio>
                      <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="DISTANCIEL">
                        En télé-présentiel
                      </Radio>
                      <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="MIXTE">
                        Mixte
                      </Radio>
                    </Space>
                  </Radio.Group>
                )}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Domaine de formation"
              validateStatus={errors?.formationDomain?.message && 'error'}
              help={errors?.formationDomain?.message}
              required
            >
              <Controller
                name="formationDomain"
                control={control}
                render={({ field }) => (
                  <Radio.Group {...field}>
                    <Space direction="vertical">
                      <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="BATIMENT">
                        Bâtiment, modélisation (NSF 230)
                      </Radio>
                      <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="IMPRESSION">
                        Impression, édition, graphisme (NSF 322)
                      </Radio>
                      <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="INFORMATIQUE">
                        Informatique, développement (NSF 326)
                      </Radio>
                      <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="COMMUNICATION">
                        Communication digitale et web (NSF 320)
                      </Radio>
                      <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="IMAGE">
                        Techniques image, son et vidéo (NSF 323)
                      </Radio>
                    </Space>
                  </Radio.Group>
                )}
              />
            </Item>
          </Col>
        </Row>
        <Alert
          type="warning"
          showIcon
          message="Attention ! Une fois la création terminée vous ne pourrez plus modifier ces informations."
        />
        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 24 }}>
          <Button className="gray-text" disabled={isProcessing}>
            <Link to="/formao/sessions">Annuler</Link>
          </Button>
          <Button type="primary" htmlType="submit" loading={isProcessing}>
            Enregistrer
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default GroupCreate;

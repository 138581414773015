import { useParams } from 'react-router-dom';
import { useCallback, useMemo, useState } from 'react';
import { Card, Button, Tabs, Space, List, Typography, Input, Select, TreeSelect } from 'antd';
import { ArrowLeftOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';
import qs from 'qs';
import { debounce } from 'lodash';

import { aiRoutes, formationRoutes } from '../../../../lib/routes';
import tablePagination from '../../../../lib/tablePagination';
import { sort, checkAuthorization } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import ActivitiesAiItem from './ActivitiesAiItem';

const { TabPane } = Tabs;
import { searchInString } from '../../../../lib/helpers';

const getQuery = (workspace, filters) => {
  return qs.stringify(
    {
      search: filters.query,
      formations: filters.formations,
      modules: filters.modules,
      app: workspace,
    },
    { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true, arrayFormat: 'comma' },
  );
};

const ActivitiesAiList = () => {
  const { workspace } = useParams();
  const [values, setValues] = useState({
    query: null,
    modules: [],
    formations: [],
  });
  const [search, setSearch] = useState('');
  const query = useMemo(() => getQuery(workspace, values), [workspace, values]);
  const { data: application } = useSWR(`${aiRoutes.applications}/${workspace}`);
  const { data: activities, isActivitiesValidating, mutate } = useSWR(aiRoutes.activities + query);
  // TODO: Corriger le problème de filtre par module qui ignore les modules avant et après dans le template application
  const { data: modules, isModulesValidating } = useSWR(`${aiRoutes.modules}?app=general,${workspace}`);
  const { data: formations, isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const enabledActivities = activities?.data?.filter((activity) => !activity.disabled) || [];
  const disabledActivities = activities?.data?.filter((activity) => activity.disabled) || [];
  const formattedFormations = (formations?.data || [])
    .sort((a, b) => sort(a, b, 'title'))
    .map((item) => {
      return { label: item.title, value: item._id };
    });
  const { user } = useAuth();

  const onSearch = useCallback(
    debounce((value) => {
      setValues((rest) => ({ ...rest, query: value }));
    }, 300),
    [],
  );

  const onSearchChange = (event) => {
    setSearch(event.target.value);
    onSearch(event.target.value);
  };

  return (
    <Card>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
        <Link to="/ia/activites">
          <Button icon={<ArrowLeftOutlined />}>Retour à la sélection des Applications</Button>
        </Link>
        {checkAuthorization(user, 'ai', 'activities', 'create') && (
          <Link to={`/ia/activites/${workspace}/nouvelle-activite`}>
            <Button icon={<PlusOutlined />}>Nouvelle activité</Button>
          </Link>
        )}
      </div>
      <Typography.Title level={4}>Activités de l'application « {application?.data?.title} »</Typography.Title>
      <div className="activities-search-container">
        <Input
          placeholder="Rechercher"
          style={{ width: 300 }}
          value={search}
          onChange={onSearchChange}
          suffix={<SearchOutlined />}
          allowClear={true}
        />
        <Space>
          {/* <TreeSelect
            placeholder="Modules"
            mode="multiple"
            style={{ minWidth: 200 }}
            treeCheckable={true}
            filterTreeNode={(input, option) => searchInString(input, option.label)}
            value={values?.modules}
            onChange={(e) => setValues({ ...values, modules: e })}
            treeData={[
              {
                label: 'Modules généraux',
                value: 'general',
                key: 'general',
                children: (modules?.data || [])
                  .sort((a, b) => sort(a, b, 'title'))
                  .filter((item) => item.activities?.length === 0 || !item.activities)
                  .map((item) => {
                    return { label: item.title, value: item._id, key: item._id };
                  }),
              },
              {
                label: "Modules de l'activité",
                value: 'activity',
                key: 'activity',
                children: (modules?.data || [])
                  .sort((a, b) => sort(a, b, 'title'))
                  .filter((item) => item.activities?.length > 0)
                  .map((item) => {
                    return { label: item.title, value: item._id, key: item._id };
                  }),
              },
            ]}
            allowClear
            maxTagCount={1}
            disabled={!modules || isModulesValidating}
          /> */}
          <Select
            placeholder="Formation"
            mode="multiple"
            style={{ minWidth: 200 }}
            value={values?.formations}
            filterOption={(input, option) => searchInString(input, option.label)}
            onChange={(e) => setValues({ ...values, formations: e })}
            options={formattedFormations || []}
            allowClear
            maxTagCount={1}
            disabled={!formations || isFormationsValidating}
          />
        </Space>
      </div>
      <Tabs>
        <TabPane key="enabled" tab={`Actives (${enabledActivities.length})`}>
          <List
            bordered
            size="small"
            dataSource={enabledActivities}
            loading={isActivitiesValidating}
            itemLayout="horizontal"
            pagination={{ ...tablePagination(enabledActivities), size: 'small' }}
            renderItem={(item) => {
              return <ActivitiesAiItem item={item} onMutate={mutate} workspace={workspace} />;
            }}
          />
        </TabPane>
        <TabPane key="disabled" tab={`Inactives (${disabledActivities.length})`}>
          <List
            bordered
            size="small"
            dataSource={disabledActivities}
            loading={isActivitiesValidating}
            itemLayout="horizontal"
            pagination={{ ...tablePagination(disabledActivities), size: 'small' }}
            renderItem={(item) => {
              return <ActivitiesAiItem item={item} onMutate={mutate} workspace={workspace} />;
            }}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ActivitiesAiList;

import { capitalize } from 'lodash';
import { useMemo, useState } from 'react';
import { CopyOutlined, DeleteOutlined, FilePdfOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Table, Tag, Space, Tooltip, message, Popconfirm, Typography, Pagination } from 'antd';
import qs from 'qs';
import useSWR from 'swr';
import moment from 'moment';
import { agencyRoutes, satisfactionReportRoutes } from '../../../../lib/routes';
import { checkAuthorization, sort } from '../../../../shared/utils';
import { useAuth } from '../../../../authContext';
import useColumnDateRange from '../components/useColumnDateRange';
import useColumnSearch from '../../../../hooks/useColumnSearch';
import useFetch from '../../../../hooks/useFetch';

const formationDomains = [
  { text: 'Bâtiment, modélisation (NSF 230)', value: 'BATIMENT' },
  { text: 'Impression, édition, graphisme (NSF 322)', value: 'IMPRESSION' },
  { text: 'Informatique, développement (NSF 326)', value: 'INFORMATIQUE' },
  { text: 'Communication digitale et web (NSF 320)', value: 'COMMUNICATION' },
  { text: 'Techniques image, son et vidéo (NSF 323)', value: 'IMAGE' },
];

const realizationMethod = [
  { text: 'Présentiel', value: 'PRESENTIEL' },
  { text: 'Télé-présentiel', value: 'DISTANCIEL' },
  { text: 'Mixte', value: 'MIXTE' },
];

const steps = [
  { text: 'Envoyé', value: 'ENVOYÉ' },
  { text: 'Première relance', value: 'RELANCE_1' },
  { text: 'Deuxième relance', value: 'RELANCE_2' },
  { text: 'Troisième relance', value: 'RELANCE_3' },
  { text: 'Complété', value: 'COMPLÉTÉ' },
  { text: 'Programmé', value: 'PROGRAMMÉ' },
];

const getQuery = (page, limit) => {
  return qs.stringify(
    { page: Number(page) - 1, limit },
    { skipNulls: true, encoreValuesOnly: true, addQueryPrefix: true, arrayFormat: 'comma' },
  );
};

const SatisfactionReportList = () => {
  const [resendLoading, setResendLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const query = useMemo(() => getQuery(currentPage, pageSize), [currentPage, pageSize]);
  const {
    data: reports,
    isValidating,
    mutate,
  } = useSWR(satisfactionReportRoutes.default + query, { revalidateOnFocus: false, revalidateOnReconnect: false });
  const { data: agencies } = useSWR(agencyRoutes.default);
  const { getColumnSearchProps } = useColumnSearch();
  const { getColumnDateRangeProps } = useColumnDateRange();
  const { get, remove } = useFetch();
  const { user } = useAuth();

  const popconfirmProps = {
    title: 'Êtes-vous sûr ?',
    cancelText: 'Non',
    okText: 'Oui',
    placement: 'left',
  };

  const downloadPdf = async (report) => {
    const file = await get(satisfactionReportRoutes.download + '/' + report._id);
    //prettier-ignore
    const fileName = `BILAN-A-CHAUD_${report.type === 'COMPANY' ? 'ENT' : 'IND'}_${report.student.lastName}-${report.student.firstName}_${moment(report.report.date).format('DD-MM-YYYY')}-.pdf`;

    if (file.status === 201) {
      const blob = new Blob([Buffer.from(file.data.data)], { type: 'application/pdf' });
      const link = document.createElement('a');

      link.href = window.URL.createObjectURL(blob);
      link.target = '_blank';
      link.download = fileName;
      link.click();
    } else {
      message.error('Une erreur est survenue, veuillez réessayer');
    }
  };

  const deleteReport = async (id) => {
    const results = await remove(satisfactionReportRoutes.default + '/' + id);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }
  };

  const resendMail = async (id) => {
    if (checkAuthorization(user, 'formao', 'satisfactionReports', 'resend')) {
      setResendLoading(true);

      const results = await get(satisfactionReportRoutes.resend + '/' + id);

      if (results.status === 200) {
        mutate();
        setResendLoading(false);
      } else {
        if (results.message) {
          message.error(results.message);
        }
        setResendLoading(false);
      }
    }
  };

  const columns = [
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
      align: 'center',
      width: 100,
      filters: [
        { text: 'Individuel', value: 'INDIVIDUAL' },
        { text: 'Entreprise', value: 'COMPANY' },
      ],
      onFilter: (value, record) => record.type === value,
      render: (record) => <Tag>{record === 'INDIVIDUAL' ? 'Individuel' : 'Entreprise'}</Tag>,
    },
    {
      title: 'Nom',
      key: 'lastName',
      sorter: (a, b) => sort(a, b, 'student.lastName'),
      dataIndex: ['student', 'lastName'],
      ...getColumnSearchProps('student.lastName'),
      render: (record) => record.toUpperCase(),
    },
    {
      title: 'Prénom',
      key: 'firstName',
      sorter: (a, b) => sort(a, b, 'student.firstName'),
      dataIndex: ['student', 'firstName'],
      ...getColumnSearchProps('student.firstName'),
      render: (record) => record.charAt(0).toUpperCase() + record.slice(1),
    },
    {
      title: 'Domaine de formation',
      key: 'formationDomain',
      dataIndex: ['student', 'formationDomain'],
      filters: formationDomains,
      onFilter: (value, record) => record.student.formationDomain === value,
      sorter: (a, b) => sort(a, b, 'student.formationDomain'),
      render: (record) => {
        const match = formationDomains.find((domain) => domain.value === record);

        return match ? match.text : <Typography.Text type="danger">Domaine introuvable</Typography.Text>;
      },
    },
    {
      title: 'Modalité de réalisation',
      key: 'realizationMethod',
      dataIndex: ['student', 'realizationMethod'],
      filters: realizationMethod,
      onFilter: (value, record) => record.student.realizationMethod === value,
      sorter: (a, b) => sort(a, b, 'student.realizationMethod'),
      render: (record) => {
        const match = realizationMethod.find((method) => method.value === record);

        return match ? match.text : <Typography.Text type="danger">Modalité introuvable</Typography.Text>;
      },
    },
    {
      title: 'Dates de fin de formation',
      key: 'formationDates',
      dataIndex: ['student', 'formationEndAt'],
      align: 'center',
      width: 200,
      ...getColumnDateRangeProps('student.formationEndAt'),
      render: (record) => moment(record).format('DD/MM/YYYY'),
    },
    {
      title: 'Centre',
      key: 'agency',
      filters: agencies?.data
        .sort((a, b) => sort(a, b, 'city'))
        .map((agency) => ({ text: agency.city, value: agency._id })),
      onFilter: (value, record) => record.student.agency._id === value,
      sorter: (a, b) => sort(a, b, 'student.agency.city'),
      dataIndex: ['student', 'agency', 'city'],
      render: (record) => (record ? record : <Typography.Text type="danger">Centre introuvable</Typography.Text>),
    },
    {
      title: 'Formateur',
      key: 'former',
      dataIndex: ['student', 'former'],
      render: (record) =>
        record?.last_name && record?.first_name ? (
          record?.last_name?.toUpperCase() + ' ' + capitalize(record?.first_name)
        ) : (
          <Typography.Text type="danger">Formateur introuvable</Typography.Text>
        ),
    },
    {
      title: 'Statut',
      key: 'step',
      dataIndex: 'step',
      width: 100,
      filters: steps,
      onFilter: (value, record) => {
        if (record.step) {
          return record.step === value;
        } else {
          return value === 'PROGRAMMÉ';
        }
      },
      render: (record) => {
        const match = steps.find((step) => step.value === record);

        return match ? (
          <Tag
            color={match.value !== 'COMPLÉTÉ' ? 'processing' : 'success'}
            style={{ width: '100%', textAlign: 'center' }}
          >
            {match.text}
          </Tag>
        ) : (
          <Tag style={{ width: '100%', textAlign: 'center' }}>Programmé</Tag>
        );
      },
    },
    {
      key: 'actions',
      align: 'right',
      fixed: 'right',
      width: 50,
      render: (record) => (
        <Space>
          <Tooltip title="Copier le lien du bilan">
            <Button
              icon={<CopyOutlined />}
              onClick={() => {
                if (record.token && !['COMPLÉTÉ', 'PROGRAMMÉ'].includes(record.step) && record.step) {
                  navigator.clipboard.writeText(`https://bilan.arinfo.fr/?t=${record.token}`);
                }
              }}
              disabled={!record.token || ['COMPLÉTÉ', 'PROGRAMMÉ'].includes(record.step) || !record.step}
            />
          </Tooltip>
          {checkAuthorization(user, 'formao', 'satisfactionReports', 'resend') && (
            <Tooltip title="Renvoyer le bilan">
              <Button
                icon={<MailOutlined />}
                onClick={() => resendMail(record._id)}
                loading={resendLoading}
                disabled={!record.token || ['COMPLÉTÉ', 'PROGRAMMÉ'].includes(record.step) || !record.step}
              />
            </Tooltip>
          )}
          {checkAuthorization(user, 'formao', 'satisfactionReports', 'download') && (
            <Tooltip title="Consulter le PDF" destroyTooltipOnHide={{ keepParent: false }}>
              <Button
                className="gray-text"
                disabled={!record.report.date || record.step !== 'COMPLÉTÉ'}
                onClick={() => downloadPdf(record)}
                icon={<FilePdfOutlined />}
              />
            </Tooltip>
          )}
          {checkAuthorization(user, 'formao', 'satisfactionReports', 'delete') && (
            <Tooltip title="Supprimer" destroyTooltipOnHide={{ keepParent: false }}>
              <Popconfirm {...popconfirmProps} onConfirm={() => deleteReport(record._id)}>
                <Button className="gray-text" icon={<DeleteOutlined />} />
              </Popconfirm>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return (
    <>
      <Table
        loading={!reports || isValidating}
        dataSource={reports?.data?.items || []}
        columns={columns}
        bordered
        rowKey={(row) => row._id}
        size="small"
        pagination={false}
      />

      <div style={{ textAlign: 'right', marginTop: 16 }}>
        <Pagination
          size="small"
          current={currentPage}
          onChange={setCurrentPage}
          pageSize={pageSize}
          onShowSizeChange={(current, size) => setPageSize(size)}
          total={reports?.data?.totalItems}
          showTotal={(total, range) => {
            return `${range[0]}-${range[1]} de ${total} élément${total > 1 ? 's' : ''}`;
          }}
          showSizeChanger
          showQuickJumper
        />
      </div>
    </>
  );
};

export default SatisfactionReportList;

import { addMonths, eachMonthOfInterval, getDate, getDaysInMonth, nextMonday, setDate } from 'date-fns';
import { Alert, AutoComplete, Col, DatePicker, Divider, Form, Input, Row, Select, Space, Tooltip } from 'antd';
import { useFormContext, Controller, useFormState } from 'react-hook-form';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useMemo, useState } from 'react';
import { capitalize } from 'lodash';
import useSWR from 'swr';
import DebounceAutoComplete from './DebounceAutoComplete';
import useFetch from '../../../../hooks/useFetch';
import { formationRoutes, nextAgencyRoutes, userRoutes } from '../../../../lib/routes';
import { searchInString } from '../../../../lib/helpers';
import { sort } from '../../../../shared/utils';

const NonFormaoStudentForm = ({ disabled, setFormsOptions, toggleShowAllForms, formattedForms, isEditing }) => {
  const [formerOptions, setFormerOptions] = useState([]);
  const [formationOptions, setFormationOptions] = useState([]);
  const [referentOptions, setReferentOptions] = useState([]);
  const { control, setValue, watch } = useFormContext();
  const { errors } = useFormState({ control });
  const { get } = useFetch();
  const { data: formations } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(nextAgencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const formattedAgencies = useMemo(
    () => agencies?.data.map((agency) => ({ label: agency.city, value: agency._id })),
    [agencies],
  );
  const reportsType = watch('reportsType');
  const selectedFormation = watch('studentFormation');

  const fetchUserList = async (value) => {
    const results = await get(userRoutes.default + '?search=' + value);

    if (results.status !== 200) {
      return [];
    } else {
      const formattedData = results.data.map((result) => ({
        label: result.last_name.toUpperCase() + ' ' + capitalize(result.first_name),
        value: result._id,
      }));
      const sortedData = formattedData.sort((a, b) => sort(a, b, 'label'));

      return sortedData;
    }
  };

  const formationSearch = (value) => {
    if (formations?.data) {
      const filtered = formations.data.filter(
        (formation) => !formation.disabled && searchInString(value, formation.title),
      );
      const formatted = filtered.map((result) => ({ label: result.title, value: result._id }));
      const sorted = formatted.sort((a, b) => sort(a, b, 'label'));

      setFormationOptions(sorted);
    } else {
      setFormationOptions([]);
    }
  };

  const handleFormation = (value, onChange) => {
    const reports = watch('reports');

    if (value) {
      const formattedValue = value?.label ? value.value : value;

      const newOptions = formattedForms.filter((item) =>
        item.formations.find((formation) => formation.toString() === formattedValue.toString()),
      );

      toggleShowAllForms(newOptions.filter((option) => option.formType === reportsType).length === 0);
      setFormsOptions(newOptions);
    }

    if (typeof value === 'object') {
      const filteredForms = formattedForms.filter(
        (form) => form.formations.includes(value?.value?.toString()) && form.formType === reportsType,
      );

      if (filteredForms?.length !== 0) {
        if (reportsType === 'COMPANY') {
          const firstReportForm = filteredForms.filter((form) => form.reportType === 'INTEGRATION');
          const secondReportForm = filteredForms.filter((form) => form.reportType === 'MI_PARCOURS');
          const thirdReportForm = filteredForms.filter((form) => form.reportType === 'FINAL');

          if (firstReportForm?.length === 1) {
            setValue(`reports.[0].form`, firstReportForm[0]);
          }

          if (secondReportForm?.length === 1) {
            setValue(`reports.[1].form`, secondReportForm[0]);
          }

          if (thirdReportForm?.length === 1) {
            setValue(`reports.[2].form`, thirdReportForm[0]);
          }
        }

        if (reportsType === 'FORMATION') {
          const firstReportForm = filteredForms.filter((form) => form.reportType === 'MI_PARCOURS');
          const secondReportForm = filteredForms.filter((form) => form.reportType === 'FINAL');

          if (firstReportForm?.length === 1) {
            setValue(`reports.[0].form`, firstReportForm[0]);
          }

          if (secondReportForm?.length === 1) {
            setValue(`reports.[1].form`, secondReportForm[0]);
          }
        }

        if (reportsType === 'MENSUEL') {
          if (filteredForms.length === 1) {
            for (let i = 0; i < reports.length; i++) {
              setValue(`reports.[${i}].form`, filteredForms[0]);
            }
          } else {
            for (let i = 0; i < reports.length; i++) {
              setValue(`reports.[${i}].form`, null);
            }
          }
        }

        if (reportsType === 'STAGE') {
          if (filteredForms?.length === 1) {
            setValue(`reports.[0].form`, filteredForms[0]);
          } else {
            setValue(`reports.[0].form`, null);
          }
        }
      }
    } else {
      for (let i = 0; i < reports.length; i++) {
        setValue(`reports.[${i}].form`, null);
      }
    }

    onChange(value);
  };

  const handleFormationDates = (value, onChange) => {
    if (reportsType === 'COMPANY') {
      const firstReportDate = addMonths(new Date(value[0]), 2);
      const secondReportDate = addMonths(new Date(value[0]), 6);
      const thirdReportDate = addMonths(new Date(value[0]), 10);

      setValue(`reports.[0].sendAt`, firstReportDate.getDay() === 1 ? firstReportDate : nextMonday(firstReportDate));
      setValue(`reports.[1].sendAt`, secondReportDate.getDay() === 1 ? secondReportDate : nextMonday(secondReportDate));
      setValue(`reports.[2].sendAt`, thirdReportDate.getDay() === 1 ? thirdReportDate : nextMonday(thirdReportDate));
    }

    if (reportsType === 'FORMATION') {
      const firstReportDate = addMonths(new Date(value[0]), 3);
      const secondReportDate = addMonths(new Date(value[0]), 6);

      setValue(`reports.[0].sendAt`, firstReportDate.getDay() === 1 ? firstReportDate : nextMonday(firstReportDate));
      setValue(`reports.[1].sendAt`, secondReportDate.getDay() === 1 ? secondReportDate : nextMonday(secondReportDate));
    }

    if (reportsType === 'MENSUEL') {
      // Récupère tous les mois entre le début et la fin de formation
      let eachMonths = eachMonthOfInterval({ start: new Date(value[0]), end: new Date(value[1]) });
      // Récupère la date de début de formation
      const startDate = getDate(new Date(value[0]));
      // Récupère le nombre de jours dans le mois de la date de début de formation
      const startDateDaysInMonth = getDaysInMonth(new Date(value[0]));
      const formattedMonths = eachMonths?.map((month) => {
        // Récupère le nombre de jours dans le mois
        const daysInMonth = getDaysInMonth(new Date(month));
        // Définie la date en fonction de la date du début de formation
        const formattedDate = setDate(new Date(month), daysInMonth < startDate ? daysInMonth : startDate);
        // Définie la date qui permettra l'affichage dans la liste des suivis (sans la prise en compte des w-e)
        const originalDate = startDate <= startDateDaysInMonth / 2 ? formattedDate : addMonths(formattedDate, 1);
        // Définie la date d'envoi du suivi
        const sendAt = addMonths(formattedDate, 1);
        // Définie la date d'envoi du suivi qui prend en compte les w-e
        const formattedSendAt = [6, 0].includes(sendAt.getDay()) ? nextMonday(sendAt) : sendAt;
        let reportForm = null;

        // Défini le formulaire sélectionné par défaut en fonction de la formation
        if (selectedFormation && typeof selectedFormation === 'object') {
          const filteredForms = formattedForms.filter(
            (form) => form.formations.includes(selectedFormation?.value?.toString()) && form.formType === 'MENSUEL',
          );

          if (filteredForms?.length === 1) {
            reportForm = filteredForms[0];
          }
        }

        return { originalDate, sendAt: formattedSendAt, form: reportForm };
      });

      setValue('reports', formattedMonths);
    }

    onChange(value);
  };

  return (
    <Space direction="vertical" style={{ width: '100%' }}>
      <Divider>Informations du Stagiaire</Divider>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Email"
            validateStatus={errors?.studentEmail?.message && 'error'}
            help={errors?.studentEmail?.message}
            required
          >
            <Controller
              name="studentEmail"
              control={control}
              render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple : john.doe@mail.com" />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Téléphone"
            validateStatus={errors?.studentPhone?.message && 'error'}
            help={errors?.studentPhone?.message}
          >
            <Controller
              name="studentPhone"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={(event) => field.onChange(event.target.value.replace(/[^0-9]/g, ''))}
                  disabled={disabled}
                  maxLength={10}
                  allowClear
                  placeholder="Exemple : 0607080910"
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Nom"
            validateStatus={errors?.studentLastName?.message && 'error'}
            help={errors?.studentLastName?.message}
            required
          >
            <Controller
              name="studentLastName"
              control={control}
              render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple : Doe" />}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Prénom"
            validateStatus={errors?.studentFirstName?.message && 'error'}
            help={errors?.studentFirstName?.message}
            required
          >
            <Controller
              name="studentFirstName"
              control={control}
              render={({ field }) => <Input {...field} disabled={disabled} placeholder="Exemple : John" />}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider>Informations sur la Formation</Divider>
      {isEditing && (
        <Alert
          type="info"
          showIcon
          message="Afin d'éviter d'éventuels conflits, il n'est pas possible de modifier la formation ainsi que ses dates."
        />
      )}
      {!isEditing && (
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Form.Item
              label={
                <>
                  Formation&nbsp;
                  <Tooltip title="Vous pouvez sélectionner une formation déjà existante ou en saisir une nouvelle.">
                    <QuestionCircleOutlined style={{ color: '#1677ff' }} />
                  </Tooltip>
                </>
              }
              validateStatus={errors?.studentFormation?.message && 'error'}
              help={errors?.studentFormation?.message}
              required
            >
              <Controller
                name="studentFormation"
                control={control}
                render={({ field }) => (
                  <AutoComplete
                    value={field?.value?.label || field?.value}
                    options={formationOptions}
                    onSearch={formationSearch}
                    onChange={(value, option) =>
                      handleFormation(option?.label ? option : value || null, field.onChange)
                    }
                    disabled={disabled}
                    placeholder="Commencez à taper pour charger la liste..."
                  />
                )}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Dates de la formation"
              validateStatus={errors?.studentFormationDates?.message && 'error'}
              help={errors?.studentFormationDates?.message}
              required
            >
              <Controller
                name="studentFormationDates"
                control={control}
                render={({ field }) => (
                  <DatePicker.RangePicker
                    {...field}
                    onChange={(value) => handleFormationDates(value, field.onChange)}
                    format="DD/MM/YYYY"
                    disabled={disabled}
                    allowClear={false}
                  />
                )}
              />
            </Form.Item>
          </Col>
        </Row>
      )}
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Centre"
            validateStatus={errors?.studentAgency?.message && 'error'}
            help={errors?.studentAgency?.message}
            required
          >
            <Controller
              name="studentAgency"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(e) => field.onChange(e || null)}
                  filterSort={(a, b) => sort(a, b, 'label')}
                  options={formattedAgencies}
                  disabled={disabled || !agencies || isAgenciesValidating}
                  placeholder="Sélectionnez dans la liste"
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[20, 20]}>
        <Col span={12}>
          <Form.Item
            label="Formateur référent"
            validateStatus={errors?.studentFormer?.message && 'error'}
            help={errors?.studentFormer?.message}
            required
          >
            <DebounceAutoComplete
              fieldName="studentFormer"
              fetchOptions={fetchUserList}
              options={formerOptions}
              setOptions={setFormerOptions}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Responsable du suivi"
            validateStatus={errors?.referent?.message && 'error'}
            help={errors?.referent?.message}
            required
          >
            <DebounceAutoComplete
              fieldName="referent"
              fetchOptions={fetchUserList}
              options={referentOptions}
              setOptions={setReferentOptions}
              disabled={disabled}
            />
          </Form.Item>
        </Col>
      </Row>
    </Space>
  );
};
export default NonFormaoStudentForm;
0;

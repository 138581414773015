import {
  Button,
  Tabs,
  Popconfirm,
  Space,
  Tooltip,
  message,
  Menu,
  Dropdown,
  Skeleton,
  Modal,
  Typography,
  List,
} from 'antd';
import { CodeOutlined, EyeOutlined, MessageOutlined, MoreOutlined } from '@ant-design/icons';
import { memo, useState } from 'react';
import { Link } from 'react-router-dom';
import { snakeCase } from 'lodash';

import { aiRoutes } from '../../../../lib/routes';
import useFetch from '../../../../hooks/useFetch';

import Item, { Meta } from 'antd/lib/list/Item';
import { useAuth } from '../../../../authContext';
import { checkAuthorization } from '../../../../shared/utils';

import PromptTestModal from './PromptTestModal';
import useSWR from 'swr';

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ModuleAiItemUsageModal = ({ data, children }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <Typography.Link onClick={() => setOpen(true)}>{children}</Typography.Link>
      <Modal
        visible={open}
        footer={false}
        bodyStyle={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}
        onOk={() => setOpen(false)}
        onCancel={() => setOpen(false)}
      >
        <Typography.Title level={5} style={{ padding: '0 16px' }}>
          {data.length} élément{data.length > 1 ? 's' : ''}
        </Typography.Title>
        <div style={{ maxHeight: 1000, overflow: 'auto' }}>
          <List
            dataSource={data}
            size="small"
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta title={item.title} />
              </List.Item>
            )}
          />
        </div>
      </Modal>
    </>
  );
};

const ModuleAiItem = ({ item, workspace, isProcessing, onProcessing, onFinish }) => {
  const [promptTestModalOpen, setPromptTestModalOpen] = useState(false);
  const { data: usage, isValidating } = useSWR(aiRoutes.modules + '/' + item._id + '/usage');
  const { user } = useAuth();
  const { post, patch, remove } = useFetch();

  const enableModule = async () => {
    onProcessing(true);

    const results = await patch(aiRoutes.modules + '/enable/' + item._id);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const disableModule = async () => {
    onProcessing(true);

    const results = await patch(aiRoutes.modules + '/disable/' + item._id);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const duplicateModule = async () => {
    onProcessing(true);

    const results = await post(
      aiRoutes.modules + '/' + item._id + '/copy',
      JSON.stringify({ title: item.title + ' (copy)' }),
    );

    if (results.status === 201) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const exportModule = async () => {
    // Exportation au format JSON
    const extractToJson = ({ title, content, variables }) => {
      const formattedVariables = variables.map((item) => {
        delete item._id;
        return item;
      });
      return { title, content, variables: formattedVariables };
    };

    // Création du fichier
    const filename = snakeCase(item.title);
    const blob = new Blob([JSON.stringify(extractToJson(item), null, 2)], { type: 'application/json' });

    // Construction du lien
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = href;
    link.target = '_blank';
    link.download = `${filename}.json`;

    // Démarrer le téléchargement
    document.body.appendChild(link);
    link.click();

    // Nettoyer
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
  };

  const deleteModule = async () => {
    onProcessing(true);

    const results = await remove(aiRoutes.modules + '/' + item._id);

    if (results.status === 200) {
      onFinish();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    onProcessing(false);
  };

  const Actions = memo(({ item }) => {
    return (
      <Menu key={`menu-${item._id}`}>
        {workspace === 'general' ? (
          <>
            {/* MENU POUR MODULES GENERAUX */}
            {item.disabled
              ? checkAuthorization(user, 'ai', 'generalModules', 'enable') && (
                  <Menu.Item key="enable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={enableModule}>
                      Activer
                    </Popconfirm>
                  </Menu.Item>
                )
              : checkAuthorization(user, 'ai', 'generalModules', 'disable') && (
                  <Menu.Item key="disable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={disableModule}>
                      Désactiver
                    </Popconfirm>
                  </Menu.Item>
                )}
            {checkAuthorization(user, 'ai', 'generalModules', 'update') && (
              <Menu.Item key="edit" className="gray-text">
                <Link to={`/ia/modules/${workspace}/modifier-un-module/${item._id}`}>Modifier</Link>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'generalModules', 'create') && (
              <Menu.Item key="duplicate" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={duplicateModule}>
                  Dupliquer
                </Popconfirm>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'generalModules', 'export') && (
              <Menu.Item key="export" className="gray-text" onClick={() => exportModule()}>
                Exporter
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'generalModules', 'delete') && (
              <Menu.Item key="delete" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={deleteModule}>
                  Supprimer
                </Popconfirm>
              </Menu.Item>
            )}
          </>
        ) : (
          <>
            {/* MENU POUR MODULES D'APPLICATION */}
            {item.disabled
              ? checkAuthorization(user, 'ai', 'modules', 'enable') && (
                  <Menu.Item key="enable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={enableModule}>
                      Activer
                    </Popconfirm>
                  </Menu.Item>
                )
              : checkAuthorization(user, 'ai', 'modules', 'disable') && (
                  <Menu.Item key="disable" className="gray-text">
                    <Popconfirm {...popconfirmProps} onConfirm={disableModule}>
                      Désactiver
                    </Popconfirm>
                  </Menu.Item>
                )}
            {checkAuthorization(user, 'ai', 'modules', 'update') && (
              <Menu.Item key="edit" className="gray-text">
                <Link to={`/ia/modules/${workspace}/modifier-un-module/${item._id}`}>Modifier</Link>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'modules', 'create') && (
              <Menu.Item key="duplicate" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={duplicateModule}>
                  Dupliquer
                </Popconfirm>
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'modules', 'export') && (
              <Menu.Item key="export" className="gray-text" onClick={() => exportModule()}>
                Exporter
              </Menu.Item>
            )}
            {checkAuthorization(user, 'ai', 'modules', 'delete') && (
              <Menu.Item key="delete" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={deleteModule}>
                  Supprimer
                </Popconfirm>
              </Menu.Item>
            )}
          </>
        )}
      </Menu>
    );
  });

  return (
    <Item>
      <PromptTestModal
        isOpen={!!promptTestModalOpen}
        module={promptTestModalOpen}
        onClose={() => setPromptTestModalOpen(false)}
      />
      <div style={{ flex: 1 }}>
        <Meta
          avatar={
            item?.variables?.length ? (
              <CodeOutlined style={{ fontSize: '36px' }} />
            ) : (
              <MessageOutlined style={{ fontSize: '36px' }} />
            )
          }
          title={item.title}
          description={
            <Skeleton title={false} paragraph={{ rows: 1, width: 350 }} loading={isValidating && !usage?.data} active>
              {!usage?.data || (usage.data?.applications.length === 0 && usage.data?.activities.length === 0) ? (
                "Ce module n'est utilisé dans aucune activité ou application"
              ) : (
                <>
                  Ce module est utilisé dans{' '}
                  <>
                    {usage.data.applications.length === 0 ? null : (
                      <ModuleAiItemUsageModal data={usage.data.applications}>
                        {usage.data.applications.length === 1 && '1 application'}
                        {usage.data.applications.length > 1 && `${usage.data.applications.length} applications`}
                      </ModuleAiItemUsageModal>
                    )}
                  </>
                  <>{usage.data.applications.length !== 0 && usage.data.activities.length !== 0 ? ' et ' : null}</>
                  <>
                    {usage.data.activities.length === 0 ? null : (
                      <ModuleAiItemUsageModal data={usage.data.activities}>
                        {usage.data.activities.length === 1 && '1 activité'}
                        {usage.data.activities.length > 1 && `${usage.data.activities.length} activités`}
                      </ModuleAiItemUsageModal>
                    )}
                  </>
                  .
                </>
              )}
            </Skeleton>
          }
        />
        <Typography.Text type={!item?.description && 'danger'} italic={!item?.description}>
          {item?.description
            ? `${item.description}`
            : 'Les descriptions sont maintenant obligatoires pour les menus ! Mettez ce module à jour'}
        </Typography.Text>
      </div>
      <Space style={{ alignSelf: 'start' }}>
        <Tooltip title="Visualiser le prompt du module" destroyTooltipOnHide={{ keepParent: false }}>
          <Button icon={<EyeOutlined />} disabled={isProcessing} onClick={() => setPromptTestModalOpen(item)} />
        </Tooltip>
        <Dropdown overlay={<Actions item={item} />} placement="bottomRight" trigger={['click']}>
          <Button icon={<MoreOutlined />} disabled={isProcessing} />
        </Dropdown>
      </Space>
    </Item>
  );
};

export default ModuleAiItem;

import { useFormContext, Controller, useFormState, useWatch } from 'react-hook-form';
import { Form, Row, Col, DatePicker, Select, Checkbox, Space, Radio } from 'antd';
import moment from 'moment';
import useSWR from 'swr';
import { sort } from '../../../../shared/utils';
import { formationRoutes, userRoutes, agencyRoutes, groupRoutes } from '../../../../lib/routes';
import StudentCreateProgram from './StudentCreateProgram';

const { Item } = Form;

const StudentCreateFormation = ({ disabled }) => {
  const { control, setValue, clearErrors } = useFormContext();
  const { errors } = useFormState({ control });
  const customProgram = useWatch({ control, name: 'custom_formation' });
  const extraDate = useWatch({ control, name: 'extra' });
  const fromGroup = useWatch({ control, name: 'group' });
  const { data: formations, isValidating: isFormationsValidating } = useSWR(formationRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: users, isValidating } = useSWR(userRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: agencies, isValidating: isAgenciesValidating } = useSWR(agencyRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });
  const { data: groups, isValidating: isGroupsValidating } = useSWR(groupRoutes.default, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  });

  const handleFilter = (inputValue, option) => option.label.toLowerCase().includes(inputValue.toLowerCase());

  const handleGroup = (value, onChange) => {
    const groupDetails = groups.data.find((item) => item._id === value);

    if (groupDetails) {
      setValue('formation', groupDetails?.formation?._id);
      setValue('startAt', moment(groupDetails.startAt));
      setValue('endAt', moment(groupDetails?.endAt));
      setValue('referent', groupDetails?.referent?._id);
      setValue('realizationMethod', groupDetails?.realizationMethod || null);
      setValue('formationDomain', groupDetails?.formationDomain || null);

      clearErrors(['formation', 'startAt', 'endAt', 'referent', 'realizationMethod', 'formationDomain']);

      onChange(value);
    } else {
      if (!value) {
        setValue('formation', null);
        setValue('startAt', null);
        setValue('endAt', null);
        setValue('referent', null);
        setValue('agency', null);
        setValue('realizationMethod', null);
        setValue('formationDomain', null);
      }
      onChange(null);
    }
  };

  return (
    <>
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item extra="En cochant cette case vous pourrez définir une formation personnalisée pour cet apprenant">
            <Controller
              control={control}
              name="custom_formation"
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  {...field}
                  checked={field.value}
                  disabled={disabled}
                  onChange={(e) => {
                    if (extraDate > 9) setValue('extra', null);
                    return field.onChange(e.target.checked);
                  }}
                >
                  Entrée/sortie
                </Checkbox>
              )}
            />
            {customProgram && <StudentCreateProgram />}
          </Item>
        </Col>
      </Row>
      {!customProgram && (
        <Row gutter={[16, 16]}>
          <Col span={12}>
            <Item
              label="Groupe"
              validateStatus={errors?.group?.message && 'error'}
              help={errors?.group?.message}
              extra="Sélectionner un groupe préremplira le formulaire"
            >
              <Controller
                control={control}
                name="group"
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    onChange={(value) => handleGroup(value, field.onChange)}
                    placeholder="Sélectionnez dans la liste"
                    disabled={!groups || isGroupsValidating || disabled}
                    loading={isGroupsValidating}
                    options={
                      groups?.data
                        .sort((a, b) => sort(a, b, 'name'))
                        .map((group) => ({
                          label: group.name,
                          value: group._id,
                        })) || []
                    }
                    allowClear
                  />
                )}
              />
            </Item>
          </Col>
          <Col span={12}>
            <Item
              label="Formation"
              required
              validateStatus={errors?.formation?.message && 'error'}
              help={errors?.formation?.message}
              extra={fromGroup && "La formation est bloquée tant qu'un groupe est sélectionné"}
            >
              <Controller
                control={control}
                name="formation"
                render={({ field }) => (
                  <Select
                    {...field}
                    filterOption={handleFilter}
                    showSearch
                    placeholder="Sélectionnez dans la liste"
                    disabled={!formations || isFormationsValidating || disabled || fromGroup}
                    loading={isFormationsValidating}
                    options={
                      formations?.data
                        .sort((a, b) => sort(a, b, 'title'))
                        .map((program) => ({
                          label: program.title,
                          value: program._id,
                        })) || []
                    }
                  />
                )}
              />
            </Item>
          </Col>
        </Row>
      )}
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Formateur référent"
            required
            validateStatus={errors?.referent?.message && 'error'}
            help={errors?.referent?.message}
          >
            <Controller
              control={control}
              name="referent"
              render={({ field }) => (
                <Select
                  {...field}
                  filterOption={handleFilter}
                  showSearch
                  placeholder="Sélectionnez dans la liste"
                  disabled={!users || isValidating || disabled}
                  loading={isValidating}
                  options={
                    (users?.data || [])
                      .map((user) => ({
                        label: `${user.last_name.toUpperCase()} ${
                          user.first_name.charAt(0).toUpperCase() + user.first_name.slice(1)
                        }`,
                        value: user._id,
                        disabled: user.disabled,
                      }))
                      .sort((a, b) => sort(a, b, 'label')) || []
                  }
                />
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Centre"
            required
            validateStatus={errors?.agency?.message && 'error'}
            help={errors?.agency?.message}
          >
            <Controller
              control={control}
              name="agency"
              render={({ field }) => (
                <Select
                  {...field}
                  filterOption={handleFilter}
                  showSearch
                  placeholder="Sélectionnez dans la liste"
                  disabled={!agencies || isAgenciesValidating || disabled}
                  loading={isAgenciesValidating}
                  options={
                    agencies?.data
                      .map((agency) => ({
                        label: agency.city,
                        value: agency._id,
                      }))
                      .sort((a, b) => sort(a, b, 'label')) || []
                  }
                />
              )}
            />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Date d'entrée"
            required
            validateStatus={errors?.startAt?.message && 'error'}
            help={errors?.startAt?.message}
          >
            <Controller
              control={control}
              name="startAt"
              render={({ field }) => (
                <DatePicker {...field} format="DD/MM/YYYY" placeholder="Sélectionnez une date" disabled={disabled} />
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Date de fin"
            required
            validateStatus={errors?.endAt?.message && 'error'}
            help={errors?.endAt?.message}
            extra={`L'accès à Formao sera bloqué ${
              customProgram ? '1' : '3'
            } mois après cette date mais vous pouvez étendre cette durée en dessous`}
          >
            <Controller
              control={control}
              name="endAt"
              render={({ field }) => (
                <DatePicker {...field} format="DD/MM/YYYY" placeholder="Sélectionnez une date" disabled={disabled} />
              )}
            />
          </Item>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col span={12} />
        <Col span={12}>
          <Item
            label="Ajouter une durée d'accès"
            validateStatus={errors?.formations?.[index]?.endAt?.message && 'error'}
            help={errors?.formations?.[index]?.endAt?.message}
          >
            <Controller
              control={control}
              name="extra"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  disabled={disabled}
                  allowClear
                  onClear={() => field.onChange(null)}
                  options={[
                    { label: '+1 mois', value: 1 },
                    { label: '+2 mois', value: 2 },
                    { label: '+3 mois', value: 3 },
                    { label: '+4 mois', value: 4 },
                    { label: '+5 mois', value: 5 },
                    { label: '+6 mois', value: 6 },
                    { label: '+7 mois', value: 7 },
                    { label: '+8 mois', value: 8 },
                    { label: '+9 mois', value: 9 },
                    ...(customProgram
                      ? [
                          { label: '+10 mois', value: 10 },
                          { label: '+11 mois', value: 11 },
                        ]
                      : []),
                  ]}
                />
              )}
            />
          </Item>
        </Col>
      </Row>

      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Item
            label="Modalité de réalisation"
            validateStatus={errors?.realizationMethod?.message && 'error'}
            help={errors?.realizationMethod?.message}
            required
          >
            <Controller
              name="realizationMethod"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space direction="vertical">
                    <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="PRESENTIEL">
                      En présentiel
                    </Radio>
                    <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="DISTANCIEL">
                      En télé-présentiel
                    </Radio>
                    <Radio className={errors?.realizationMethod?.message && 'radio-danger'} value="MIXTE">
                      Mixte
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </Item>
        </Col>
        <Col span={12}>
          <Item
            label="Domaine de formation"
            validateStatus={errors?.formationDomain?.message && 'error'}
            help={errors?.formationDomain?.message}
            required
          >
            <Controller
              name="formationDomain"
              control={control}
              render={({ field }) => (
                <Radio.Group {...field}>
                  <Space direction="vertical">
                    <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="BATIMENT">
                      Bâtiment, modélisation (NSF 230)
                    </Radio>
                    <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="IMPRESSION">
                      Impression, édition, graphisme (NSF 322)
                    </Radio>
                    <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="INFORMATIQUE">
                      Informatique, développement (NSF 326)
                    </Radio>
                    <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="COMMUNICATION">
                      Communication digitale et web (NSF 320)
                    </Radio>
                    <Radio className={errors?.formationDomain?.message && 'radio-danger'} value="IMAGE">
                      Techniques image, son et vidéo (NSF 323)
                    </Radio>
                  </Space>
                </Radio.Group>
              )}
            />
          </Item>
        </Col>
      </Row>
    </>
  );
};

export default StudentCreateFormation;

import { memo, useState } from 'react';
import { Card, Button, Table, Tabs, Menu, Popconfirm, Space, Tooltip, Dropdown, Image } from 'antd';
import { PlusOutlined, LinkOutlined, MoreOutlined, PictureOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import useSWR from 'swr';

import { aiRoutes } from '../../../lib/routes';
import tablePagination from '../../../lib/tablePagination';
import { checkAuthorization, sort } from '../../../shared/utils';
import useColumnSearch from '../../../hooks/useColumnSearch';
import useFetch from '../../../hooks/useFetch';
import { useAuth } from '../../../authContext';
import { getImageUrl } from '../../../lib/cloudinary';

const { TabPane } = Tabs;

const popconfirmProps = {
  title: 'Êtes-vous sûr ?',
  cancelText: 'Non',
  okText: 'Oui',
  placement: 'left',
};

const ApplicationImage = memo(({ url }) => {
  const [display, setDisplay] = useState(false);

  return (
    <>
      <Button icon={<PictureOutlined />} onClick={() => setDisplay(true)} />
      <Image
        style={{ display: 'none' }}
        src={url}
        preview={{
          visible: display,
          onVisibleChange: (value) => {
            setDisplay(value);
          },
        }}
      />
    </>
  );
});
ApplicationImage.displayName = 'ApplicationImage';

const ApplicationsAiList = () => {
  const { user } = useAuth();
  const [isProcessing, setIsProcessing] = useState(false);
  const { data: applications, isValidating, mutate } = useSWR(aiRoutes.applications);
  const enabledApplications = applications?.data?.filter((applications) => !applications.disabled) || [];
  const disabledApplications = applications?.data?.filter((applications) => applications.disabled) || [];
  const { getColumnSearchProps } = useColumnSearch();
  const { patch, remove } = useFetch();

  const disableApplication = async (appId) => {
    setIsProcessing(true);

    const results = await patch(`${aiRoutes.applications}/disable/${appId}`);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const enableApplication = async (appId) => {
    setIsProcessing(true);

    const results = await patch(`${aiRoutes.applications}/enable/${appId}`);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const deleteApplication = async (appId) => {
    setIsProcessing(true);

    const results = await remove(`${aiRoutes.applications}/${appId}`);

    if (results.status === 200) {
      mutate();
    } else {
      if (results.message) {
        message.error(results.message);
      }
    }

    setIsProcessing(false);
  };

  const Actions = memo(({ record }) => {
    return (
      <Menu key={`menu-${record._id}`}>
        {record.disabled
          ? checkAuthorization(user, 'ai', 'applications', 'enable') && (
              <Menu.Item key="enable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={() => enableApplication(record._id)}>
                  Activer
                </Popconfirm>
              </Menu.Item>
            )
          : checkAuthorization(user, 'ai', 'applications', 'disable') && (
              <Menu.Item key="disable" className="gray-text">
                <Popconfirm {...popconfirmProps} onConfirm={() => disableApplication(record._id)}>
                  Désactiver
                </Popconfirm>
              </Menu.Item>
            )}
        {checkAuthorization(user, 'ai', 'applications', 'update') && (
          <Menu.Item key="edit" className="gray-text">
            <Link to={`/ia/applications/modifier-une-application/${record._id}`}>Modifier</Link>
          </Menu.Item>
        )}
        {checkAuthorization(user, 'ai', 'applications', 'delete') && (
          <Menu.Item key="delete" className="gray-text">
            <Popconfirm {...popconfirmProps} onConfirm={() => deleteApplication(record._id)}>
              Supprimer
            </Popconfirm>
          </Menu.Item>
        )}
      </Menu>
    );
  });

  const columns = [
    {
      width: 60,
      title: <span style={{ textWrap: 'nowrap' }}>Image</span>,
      dataIndex: 'image',
      key: 'image',
      render: (value) => {
        if (value) {
          console.log(value);
          const url = getImageUrl(value);
          console.log(url);
          return <ApplicationImage url={url} />;
        }
      },
    },
    {
      title: 'Intitulé',
      width: 250,
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => sort(a, b, 'title'),
      sortDirections: ['ascend', 'descend'],
      ...getColumnSearchProps('title'),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ...getColumnSearchProps('description'),
    },
    {
      title: '',
      key: 'actions',
      width: 50,
      align: 'center',
      fixed: 'right',
      render: (record) => (
        <Space>
          <Tooltip title="Copier l'ID de l'application" destroyTooltipOnHide={{ keepParent: false }}>
            <Button
              icon={<LinkOutlined />}
              onClick={() => {
                navigator.clipboard.writeText(record._id);
              }}
            />
          </Tooltip>

          <Dropdown overlay={<Actions record={record} />} placement="bottomRight" trigger={['click']}>
            <Button icon={<MoreOutlined />} disabled={isProcessing} />
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Card>
      <div style={{ textAlign: 'right', marginBottom: 20 }}>
        <Space>
          {checkAuthorization(user, 'ai', 'applications', 'create') && (
            <Link to="/ia/applications/nouvelle-application">
              <Button icon={<PlusOutlined />}>Nouvelle application</Button>
            </Link>
          )}
        </Space>
      </div>
      <Tabs>
        <TabPane key="enabled" tab={`Actives (${enabledApplications.length})`}>
          <Table
            loading={!applications || isValidating || isProcessing}
            dataSource={enabledApplications}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(enabledApplications)}
          />
        </TabPane>
        <TabPane key="disabled" tab={`Inactives (${disabledApplications.length})`}>
          <Table
            loading={!applications || isValidating || isProcessing}
            dataSource={disabledApplications}
            columns={columns}
            bordered
            size="small"
            rowKey={(row) => row._id}
            pagination={tablePagination(disabledApplications)}
          />
        </TabPane>
      </Tabs>
    </Card>
  );
};

export default ApplicationsAiList;

import { Card, Col, Form, Input, InputNumber, Row, Select, Space, Typography } from 'antd';
import { Controller, useFormContext, useFormState } from 'react-hook-form';
import Editor from '../../../../../ui/Editor';
import { StarFilled } from '@ant-design/icons';

export default function MainConfig() {
  const { control, disabled } = useFormContext();
  const { errors } = useFormState({ control });

  return (
    <Card size="small" title={<Typography.Title level={5}>Configuration principale</Typography.Title>}>
      <Row gutter={[24, 24]}>
        <Col span={12}>
          <Form.Item
            label="Durée"
            required
            validateStatus={errors?.minMaxDays && 'error'}
            help={errors?.minMaxDays?.message}
          >
            <Controller
              {...{ control }}
              name="minMaxDays"
              render={({ field }) => {
                const isValidFormat = Array.isArray(field.value) && field.value.length === 2;
                const min = isValidFormat ? field.value[0] : null;
                const max = isValidFormat ? field.value[1] : null;

                const handleMinChange = (value) => {
                  field.onChange([value, max]);
                };

                const handleMaxChange = (value) => {
                  field.onChange([min, value]);
                };

                return (
                  <Space.Compact>
                    <InputNumber
                      {...{ disabled }}
                      min={0}
                      max={max === null ? null : max}
                      value={min}
                      onChange={handleMinChange}
                      addonBefore="De"
                    />
                    <InputNumber
                      {...{ disabled }}
                      min={min === null || min === 0 ? null : min}
                      value={max}
                      onChange={handleMaxChange}
                      addonBefore="à"
                      addonAfter="jours"
                      className="input-number-group"
                    />
                  </Space.Compact>
                );
              }}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Prérequis"
            validateStatus={errors?.prerequisiteLevel && 'error'}
            help={errors?.prerequisiteLevel?.message}
            required
          >
            <Controller
              {...{ control }}
              name="prerequisiteLevel"
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Sélectionnez dans la liste"
                  options={[
                    {
                      label: (
                        <Space>
                          <div>
                            <StarFilled />
                          </div>
                          Minimums
                        </Space>
                      ),
                      value: 1,
                    },
                    {
                      label: (
                        <Space>
                          <div>
                            <StarFilled />
                            <StarFilled />
                          </div>
                          Moyens
                        </Space>
                      ),
                      value: 2,
                    },
                    {
                      label: (
                        <Space>
                          <div>
                            <StarFilled />
                            <StarFilled />
                            <StarFilled />
                          </div>
                          Élevés
                        </Space>
                      ),
                      value: 3,
                    },
                  ]}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Form.Item
        label="Description rapide"
        required
        tooltip="Une description rapide présentant la formation."
        validateStatus={errors?.description && 'error'}
        help={errors?.description?.message}
      >
        <Controller
          {...{ control }}
          name="description"
          render={({ field }) => <Input.TextArea autoSize {...{ ...field, disabled }} />}
        />
      </Form.Item>
      <Form.Item
        label="À qui s’adresse la formation ?"
        required
        style={{ marginBottom: 0 }}
        validateStatus={errors?.target && 'error'}
        help={errors?.target?.message}
      >
        <Controller
          {...{ control }}
          name="target"
          render={({ field }) => (
            <Editor
              {...{ ...field, disabled }}
              className={errors?.target && 'error'}
              minHeight={100}
              options={{
                heading: false,
                figure: false,
                color: false,
                important: false,
                highlight: false,
                code: false,
                codeBlock: false,
                youtube: false,
                vimeo: false,
                cloudinaryVideo: false,
                blockquote: false,
                file: false,
                table: false,
                grid: false,
                details: false,
                textAlign: false,
                variable: false,
              }}
            />
          )}
        />
      </Form.Item>
    </Card>
  );
}
